import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Form as BootstrapForm, Button, Col, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import ImageDropzone from "./Fields/ImageDropzone";
import TimePicker from "react-time-picker";
import FormServices from "../Services/FormServices";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Direction } from "react-data-table-component";

const EnAddClinicForm = () => {
  const selectAreaRef = useRef();

  const [facilities, setFacilities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFacilities();
    fetchSpecialities();
    fetchLocations();
  }, []);

  const fetchCitiesByLocation = async (locationId) => {
    try {
      const data = await FormServices.fetchCities(locationId);
      setCities(data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAreasByCity = async (cityId) => {
    try {
      const data = await FormServices.fetchAreas(cityId);
      setAreas(data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSpecialities = async () => {
    try {
      const data = await FormServices.fetchSpecialities();
      setSpecialities(data);
    } catch (err) {
    } finally {
    }
  };

  const fetchFacilities = async () => {
    try {
      const data = await FormServices.fetchFacilities();
      setFacilities(data);
    } catch (err) {
    } finally {
    }
  };

  const fetchLocations = async () => {
    try {
      const data = await FormServices.fetchLocations();
      setLocations(data);
    } catch (err) {
    } finally {
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    slug: Yup.string().required("Slug is required"),
    description: Yup.string().required("Description is required"),
    status: Yup.string().required("Status is required"),
    specialities: Yup.array().min(1, "At least one specialty is required"),
    facilities: Yup.array().min(1, "At least one facility is required"),
    location: Yup.string().required("Location is required"),
    city: Yup.string().required("City is required"),
    area: Yup.string().required("Area is required"),
    featureImage: Yup.string().required("Feature Image is required"),
  });

  const initialValues = {
    title: "",
    artitle: "",
    slug: "",
    description: "",
    status: "published",
    facilities: "",
    specialities: "",
    location: "",
    city: "",
    area: "",
    featureImage: "",
    insurance: false,
    seo: {
      title: "",
      description: "",
      keywords: "",
    },
    details: {
      advertisementImage: "",
      link: "",
      bookNowBtn: "",
      landmark: "",
      address: "",
      longitude: "",
      latitude: "",
      phone: "",
      mobile: "",
      email: "",
      gallery: [],
    },
    timings: [
      {
        day: "",
        fromTimeFirstHalf: "",
        toTimeFirstHalf: "",
        fromTimeSecondHalf: "",
        toTimeSecondHalf: "",
        offDay: false,
      },
    ],
    doctors: {
      doctors: [],
      careGivers: "",
      is24Hours: false,
    },
    unitIncharge: {
      isActive: false,
      title: "",
      doctorImage: "",
      bgImage: "",
      text: "",
    },
    testimonials: [
      {
        image: "",
        title: "",
        testimonial: "",
        address: "",
      },
    ],
  };

  const onSubmit = async (values) => {
    // return console.log("values", values);
    try {
      await FormServices.createClinic(values);
      navigate(`/admin/clinics-list`);
      toast.success("Clinic has been added successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          setFieldValue,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="card mb-3">
                  <div class="card-body">
                    <BootstrapForm.Group controlId="title" className="mb-3">
                      <BootstrapForm.Label>Title</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Title Here"
                        name="title"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue("title", event.target.value);
                          setFieldValue(
                            "slug",
                            event.target.value
                              .split(" ")
                              .join("-")
                              .toLowerCase()
                          );
                        }}
                      />
                      {errors.title && touched.title ? (
                        <div className="text-danger">{errors.title}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="slug" className="mb-3">
                      <BootstrapForm.Label>Slug</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Slug Here"
                        name="slug"
                        className="form-control"
                      />
                      {errors.slug && touched.slug ? (
                        <div className="text-danger">{errors.slug}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="description"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Description</BootstrapForm.Label>
                      <Editor
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                        initialValue={""}
                        onEditorChange={(content) =>
                          setFieldValue("description", content)
                        }
                        init={{
                          height: 400,
                          menubar: false,
                          directionality: "ltr",
                          language: "en",
                          language_url: "",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | styles | formatselect | " +
                            "bold italic underline strikethrough | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist outdent indent | " +
                            "link image media table blockquote | selectall",
                          style_formats: [
                            { title: "Heading 1", block: "h1" },
                            { title: "Heading 2", block: "h2" },
                            { title: "Heading 3", block: "h3" },
                            { title: "Heading 4", block: "h4" },
                            { title: "Heading 5", block: "h5" },
                            { title: "Heading 6", block: "h6" },
                            { title: "Paragraph", block: "p" },
                            { title: "Blockquote", block: "blockquote" },
                            { title: "Code", block: "code" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }",
                          branding: false,
                        }}
                      />
                      {errors.description && touched.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="featureImage"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Feature Image</BootstrapForm.Label>
                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            console.log(acceptedFiles[0]);
                            setFieldValue("featureImage", acceptedFiles[0]);
                          }
                        }}
                      />
                      {errors.featureImage && touched.featureImage ? (
                        <div className="text-danger">{errors.featureImage}</div>
                      ) : null}
                    </BootstrapForm.Group>
                  </div>
                </div>

                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Details</h5>
                  </div>
                  <div class="card-body">
                    {/* Details Section */}
                    <BootstrapForm.Group
                      controlId="details.advertisementImage"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>
                        Advertisement Image
                      </BootstrapForm.Label>
                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            console.log(acceptedFiles[0]);
                            setFieldValue(
                              "details.advertisementImage",
                              acceptedFiles[0]
                            );
                          }
                        }}
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.link"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Link</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Link Here"
                        name="details.link"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.bookNowBtn"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Book Now Button</BootstrapForm.Label>
                      <Field
                        placeholder="Enter BookNow link Here"
                        name="details.bookNowBtn"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.landmark"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Landmark</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Landmark Here"
                        name="details.landmark"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.address"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Address</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Address Here"
                        name="details.address"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.longitude"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Longitude</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Longitude Here"
                        name="details.longitude"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.latitude"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Latitude</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Latitude Here"
                        name="details.latitude"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.phone"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Telephone</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Telephone Here"
                        name="details.phone"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.mobile"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Fax</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Fax Here"
                        name="details.mobile"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.email"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Email</BootstrapForm.Label>
                      <Field
                        placeholder="Enter Email Here"
                        name="details.email"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.gallery"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Gallery</BootstrapForm.Label>

                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            const updatedGallery = [
                              ...values.details.gallery,
                              ...acceptedFiles,
                            ];
                            setFieldValue("details.gallery", updatedGallery);
                          }
                        }}
                      />

                      {errors.details?.gallery && touched.details?.gallery ? (
                        <div className="text-danger">
                          {errors.details.gallery}
                        </div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="insurance" className="mb-3">
                      <BootstrapForm.Label>Show Insurance</BootstrapForm.Label>
                      <div className="col-12">
                        <div className="form-check">
                          <Field
                            name="insurance"
                            type="checkbox"
                            className="form-check-input"
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                    </BootstrapForm.Group>
                  </div>
                </div>
                <div className="card mb-3">
                  <h5 className="card-header">SEO</h5>

                  <div class="card-body">
                    <div className="mb-3">
                      <label className="form-label">Keywords</label>
                      <Field className="form-control" name="seo.keywords" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <Field className="form-control" name="seo.description" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <Field className="form-control" name="seo.title" />
                    </div>
                  </div>
                </div>

                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Timings</h5>
                  </div>
                  <div class="card-body">
                    {/* Timings Section */}

                    <FieldArray name="timings">
                      {({ insert, remove, push }) => (
                        <div className="timings-container">
                          <Row>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>Day</BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  From Time (First Half)
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  To Time (First Half)
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  From Time (Second Half)
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  To Time (Second Half)
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  Off Day
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            {/* <Col>
                                                            </Col> */}
                          </Row>
                          {values.timings.map((timing, index) => (
                            <div key={index}>
                              <Row>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.day`}
                                    className="mb-3"
                                  >
                                    <Field
                                      name={`timings.${index}.day`}
                                      as="select"
                                      className="form-control"
                                    >
                                      <option value="">Select Day</option>
                                      <option value="Sunday">Sunday</option>
                                      <option value="Monday">Monday</option>
                                      <option value="Tuesday">Tuesday</option>
                                      <option value="Wednesday">
                                        Wednesday
                                      </option>
                                      <option value="Thursday">Thursday</option>
                                      <option value="Friday">Friday</option>
                                      <option value="Saturday">Saturday</option>
                                    </Field>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2}>
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.fromTimeFirstHalf`}
                                    className="mb-3"
                                  >
                                    <TimePicker
                                      format="h:m a"
                                      onChange={(value) =>
                                        setFieldValue(
                                          `timings.${index}.fromTimeFirstHalf`,
                                          value
                                        )
                                      }
                                      value={
                                        values.timings[index].fromTimeFirstHalf
                                      }
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2}>
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.toTimeFirstHalf`}
                                    className="mb-3"
                                  >
                                    <TimePicker
                                      format="h:m a"
                                      onChange={(value) =>
                                        setFieldValue(
                                          `timings.${index}.toTimeFirstHalf`,
                                          value
                                        )
                                      }
                                      value={
                                        values.timings[index].toTimeFirstHalf
                                      }
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2}>
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.fromTimeSecondHalf`}
                                    className="mb-3"
                                  >
                                    <TimePicker
                                      format="h:m a"
                                      onChange={(value) =>
                                        setFieldValue(
                                          `timings.${index}.fromTimeSecondHalf`,
                                          value
                                        )
                                      }
                                      value={
                                        values.timings[index].fromTimeSecondHalf
                                      }
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2}>
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.toTimeSecondHalf`}
                                    className="mb-3"
                                  >
                                    <TimePicker
                                      format="h:m a"
                                      onChange={(value) =>
                                        setFieldValue(
                                          `timings.${index}.toTimeSecondHalf`,
                                          value
                                        )
                                      }
                                      value={
                                        values.timings[index].toTimeSecondHalf
                                      }
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2}>
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.offDay`}
                                    className="mb-3"
                                  >
                                    <div className="col-12 d-flex align-items-center gap-2">
                                      <div className="form-check">
                                        <Field
                                          name={`timings.${index}.offDay`}
                                          type="checkbox"
                                          className="form-check-input"
                                        />
                                        <label
                                          className="form-check-label"
                                          for="deliveryAdd"
                                        >
                                          {" "}
                                          Yes{" "}
                                        </label>
                                      </div>
                                      <span
                                        class="badge badge-center rounded-pill bg-danger"
                                        onClick={() => remove(index)}
                                      >
                                        {" "}
                                        <i class="ti ti-x ti-xs"></i>
                                      </span>
                                    </div>
                                  </BootstrapForm.Group>
                                </Col>
                                {/* <Col sm={2}>
                                                                        

                                                                    </Col> */}
                              </Row>
                            </div>
                          ))}

                          <div class="mb-0">
                            <Button
                              size="sm"
                              className="btn btn-primary waves-effect waves-light mb-4"
                              variant="primary"
                              onClick={() =>
                                push({
                                  day: "",
                                  fromTimeFirstHalf: "",
                                  toTimeFirstHalf: "",
                                  fromTimeSecondHalf: "",
                                  toTimeSecondHalf: "",
                                  offDay: false,
                                })
                              }
                            >
                              <i className="ti ti-plus ti-xs me-2"></i>
                              <span className="align-middle">Add</span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>

                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Doctors</h5>
                  </div>
                  <div class="card-body">
                    {/* Doctors Section */}

                    <BootstrapForm.Group
                      controlId="doctors.doctors"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Doctors</BootstrapForm.Label>
                      <Select
                        isMulti
                        name="doctors.doctors"
                        options={doctors.map((doctor) => ({
                          value: doctor._id,
                          label: doctor.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) =>
                          setFieldValue("doctors.doctors", selectedOptions)
                        }
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="doctors.careGivers"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Care Givers</BootstrapForm.Label>
                      <Field
                        name="doctors.careGivers"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="doctors.is24Hours"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>24 Hours</BootstrapForm.Label>
                      <div className="col-12">
                        <div className="form-check">
                          <Field
                            name="doctors.is24Hours"
                            type="checkbox"
                            className="form-check-input"
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                    </BootstrapForm.Group>
                  </div>
                </div>

                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Unit Incharge</h5>
                  </div>
                  <div class="card-body">
                    {/* Unit Incharge Section */}

                    <BootstrapForm.Group
                      controlId="unitIncharge.isActive"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Is Active</BootstrapForm.Label>
                      <div className="col-12">
                        <div className="form-check">
                          <Field
                            name="unitIncharge.isActive"
                            type="checkbox"
                            className="form-check-input"
                          />
                        </div>
                      </div>
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="unitIncharge.title"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Title</BootstrapForm.Label>
                      <Field
                        name="unitIncharge.title"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="unitIncharge.doctorImage"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Doctor Image</BootstrapForm.Label>
                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            console.log(acceptedFiles[0]);
                            setFieldValue(
                              "unitIncharge.doctorImage",
                              acceptedFiles[0]
                            );
                          }
                        }}
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="unitIncharge.bgImage"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>BG Image</BootstrapForm.Label>
                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            console.log(acceptedFiles[0]);
                            setFieldValue(
                              "unitIncharge.bgImage",
                              acceptedFiles[0]
                            );
                          }
                        }}
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="unitIncharge.text"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Text</BootstrapForm.Label>
                      <Editor
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API Key
                        initialValue={""} // Empty initial value
                        onEditorChange={(content) =>
                          setFieldValue("unitIncharge.text", content)
                        }
                        init={{
                          height: 400,
                          menubar: false,
                          directionality: "ltr",
                          language: "en",
                          language_url: "",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | styles | formatselect | " +
                            "bold italic underline strikethrough | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist outdent indent | " +
                            "link image media table blockquote | selectall",
                          style_formats: [
                            { title: "Heading 1", block: "h1" },
                            { title: "Heading 2", block: "h2" },
                            { title: "Heading 3", block: "h3" },
                            { title: "Heading 4", block: "h4" },
                            { title: "Heading 5", block: "h5" },
                            { title: "Heading 6", block: "h6" },
                            { title: "Paragraph", block: "p" },
                            { title: "Blockquote", block: "blockquote" },
                            { title: "Code", block: "code" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }",
                          branding: false,
                        }}
                      />
                    </BootstrapForm.Group>
                  </div>
                </div>
                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Testimonials</h5>
                  </div>
                  <div class="card-body">
                    {/* Testimonial Section */}
                    <FieldArray name="testimonials">
                      {({ insert, remove, push }) => (
                        <div className="testimonial-container">
                          <Row>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>Image</BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>Title</BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  Testimonial
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  Address
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col></Col>
                          </Row>
                          {values.testimonials.map((testimonial, index) => (
                            <div key={index}>
                              <Row>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`testimonials.${index}.image`}
                                    className="mb-3"
                                  >
                                    <div className="drop-testimonial">
                                      <ImageDropzone
                                        onFilesAdded={(acceptedFiles) => {
                                          if (acceptedFiles.length > 0) {
                                            console.log(acceptedFiles[0]);
                                            setFieldValue(
                                              `testimonials.${index}.image`,
                                              acceptedFiles[0]
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`testimonials.${index}.title`}
                                    className="mb-3"
                                  >
                                    <Field
                                      placeholder="Enter Title"
                                      name={`testimonials.${index}.title`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`testimonials.${index}.testimonial`}
                                    className="mb-3"
                                  >
                                    <Field
                                      placeholder="Enter Testimonial"
                                      name={`testimonials.${index}.testimonial`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`testimonials.${index}.address`}
                                    className="mb-3"
                                  >
                                    <Field
                                      placeholder="Enter Address"
                                      name={`testimonials.${index}.address`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <span
                                    class="badge badge-center rounded-pill bg-danger"
                                    onClick={() => remove(index)}
                                  >
                                    {" "}
                                    <i class="ti ti-x ti-xs"></i>
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <div class="mb-2">
                            <Button
                              size="sm"
                              className="btn btn-primary waves-effect waves-light"
                              variant="primary"
                              onClick={() =>
                                push({
                                  image: "",
                                  title: "",
                                  testimonial: "",
                                  address: "",
                                })
                              }
                            >
                              <i className="ti ti-plus ti-xs me-2"></i>
                              <span class="align-middle">Add</span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="card mb-6">
                  <div className="card-body">
                    <BootstrapForm.Group
                      controlId="specialities"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Specialities</BootstrapForm.Label>
                      <Select
                        isMulti
                        name="specialities"
                        options={specialities.map((speciality) => ({
                          value: speciality._id,
                          label: speciality.titleEn,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) =>
                          setFieldValue("specialities", selectedOptions)
                        }
                      />
                      {errors.specialities && touched.specialities ? (
                        <div className="text-danger">{errors.specialities}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="facilities"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Facilities</BootstrapForm.Label>
                      <Select
                        isMulti
                        name="facilities"
                        options={facilities.map((facility) => ({
                          value: facility._id,
                          label: facility.titleEn,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) =>
                          setFieldValue("facilities", selectedOptions)
                        }
                      />
                      {errors.facilities && touched.facilities ? (
                        <div className="text-danger">{errors.facilities}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="location" className="mb-3">
                      <BootstrapForm.Label>Location</BootstrapForm.Label>
                      <Select
                        name="location"
                        options={locations.map((location) => ({
                          value: location._id,
                          label: location.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOption) => {
                          setFieldValue("location", selectedOption.value);
                          fetchCitiesByLocation(selectedOption.value);
                        }}
                        defaultValue={initialValues.location}
                      />
                      {errors.location && touched.location ? (
                        <div className="text-danger">{errors.location}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="city" className="mb-3">
                      <BootstrapForm.Label>City</BootstrapForm.Label>
                      <Select
                        name="city"
                        options={cities.map((city) => ({
                          value: city._id,
                          label: city.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOption) => {
                          setFieldValue("city", selectedOption.value);
                          setFieldValue("area", null);
                          fetchAreasByCity(selectedOption.value);
                        }}
                      />
                      {errors.city && touched.city ? (
                        <div className="text-danger">{errors.city}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="area" className="mb-3">
                      <BootstrapForm.Label>Area</BootstrapForm.Label>
                      <Select
                        ref={selectAreaRef}
                        name="area"
                        options={areas.map((area) => ({
                          value: area._id,
                          label: area.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) =>
                          setFieldValue("area", selectedOptions.value)
                        }
                      />
                      {errors.area && touched.area ? (
                        <div className="text-danger">{errors.area}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="status" className="mb-3">
                      <BootstrapForm.Label>Status</BootstrapForm.Label>
                      <Field as="select" name="status" className="form-control">
                        <option value="published">Published</option>
                        <option value="pending">Pending</option>
                        <option value="draft">Draft</option>
                      </Field>
                      {errors.status && touched.status ? (
                        <div className="text-danger">{errors.status}</div>
                      ) : null}
                    </BootstrapForm.Group>
                    <div class="row">
                      <div class="col-12">
                        <Button
                          className="btn btn-success waves-effect waves-light"
                          variant="success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EnAddClinicForm;
