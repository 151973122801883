import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Form as BootstrapForm, Button, Col, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ImageDropzone from "./Fields/ImageDropzone";
import FormServices from "../Services/FormServices";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { toast } from "react-toastify";
import VideoDropzone from "./Fields/VideoDropZone";
import { url } from "../Components/Common/Url";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EnAddDoctorForm = () => {
  const navigate = useNavigate();
  // Validation Schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    status: Yup.string().required("Status is required"),
    description: Yup.string().required("Description is required"),
    slug: Yup.string().required("Slug is required"),
    speciality: Yup.string().required("Speciality is required"),
    featureImage: Yup.string().required("Feature Image is required"),
    // featureVideo: Yup.string(),
    // linkedBlog: Yup.string(),
  });
  const { token } = useSelector((state) => state.user);
  const [specialities, setSpecialities] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [news, setNews] = useState([]);
  const initialValues = {
    title: "",
    status: "",
    description: "",
    slug: "",
    speciality: "",
    linkedBlog: [],
    linkedNews: [],
    featureImage: null,
    featureVideo: null,
    telephone: "",
    fax: "",
    email: "",
    address: "",
    landmark: "",
    youtubeVideo: [{ video: "", title: "" }],
    testimonials: [
      {
        image: "",
        title: "",
        testimonial: "",
        address: "",
      },
    ],
    seo: {
      keywords: "",
      description: "",
      title: "",
    },
    doctorDetails: {
      externalLink: "",
      doctorProfileLink: "",
      bookNowForm: "",
      shortDescription: "",
      generalInformation: [{ title: "", content: "" }],
      researchArticles: null,
      dateOfJoining: null,
      timings: [
        {
          clinic: "",
          timing: [
            {
              day: "",
              fromTimeFirstHalf: "",
              toTimeFirstHalf: "",
              fromTimeSecondHalf: "",
              toTimeSecondHalf: "",
              offDay: false,
            },
          ],
        },
      ],
      status: "active",
      clinics: "",
      bestDoctor: false,
      logoImage: null,
    },
  };

  console.log("initial valuse", initialValues);

  const fetchSpecialities = async () => {
    try {
      const data = await FormServices.fetchSpecialities();
      setSpecialities(data);
    } catch (err) {
    } finally {
    }
  };
  const fetchBlogs = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/blog/get/`, {
        headers: {
          token: `${token}`,
        },
      });
      setBlogs(data.data);
    } catch (error) {
      toast.error("Failed to fetch blogs.");
    }
  };
  const fetchNews = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/news/get/`, {
        headers: {
          token: `${token}`,
        },
      });
      setNews(data);
    } catch (error) {
      toast.error("Failed to fetch news.");
    }
  };

  console.log("blogs", blogs);

  const fetchClinics = async () => {
    try {
      const data = await FormServices.fetchClinicsList();
      setClinics(data);
    } catch (err) {
    } finally {
    }
  };

  const onSubmit = async (values) => {
    // return console.log("values", values);
    try {
      const response = await FormServices.createDoctor(values);

      console.log(response);
      navigate(`/admin/doctors-list`);
      toast.success("Doctor has been added successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchNews();
    fetchSpecialities();
    fetchClinics();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form id="enAddDoctorForm" onSubmit={handleSubmit}>
          <div className="card mb-3">
            <div class="card-body">
              <div className="mb-3">
                <label className="form-label">Title</label>
                <Field
                  className="form-control"
                  name="title"
                  onChange={(event) => {
                    setFieldValue("title", event.target.value);
                    setFieldValue(
                      "slug",
                      event.target.value.split(" ").join("-").toLowerCase()
                    );
                  }}
                />
                {errors.title && touched.title ? (
                  <div className="text-danger">{errors.title}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form-label">Slug</label>
                <Field className="form-control" name="slug" />
                {errors.slug && touched.slug ? (
                  <div className="text-danger">{errors.slug}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label className="form-label">Description</label>
                <Editor
                  apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                  initialValue={""}
                  onEditorChange={(content) =>
                    setFieldValue("description", content)
                  }
                  init={{
                    height: 400,
                    menubar: false,
                    directionality: "ltr",
                    language: "en",
                    language_url: "",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | styles | formatselect | " +
                      "bold italic underline strikethrough | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist outdent indent | " +
                      "link image media table blockquote | selectall",
                    style_formats: [
                      { title: "Heading 1", block: "h1" },
                      { title: "Heading 2", block: "h2" },
                      { title: "Heading 3", block: "h3" },
                      { title: "Heading 4", block: "h4" },
                      { title: "Heading 5", block: "h5" },
                      { title: "Heading 6", block: "h6" },
                      { title: "Paragraph", block: "p" },
                      { title: "Blockquote", block: "blockquote" },
                      { title: "Code", block: "code" },
                    ],
                    content_style:
                      "body { font-family: Arial, sans-serif; font-size: 14px; }",
                    branding: false,
                  }}
                />
                {errors.description && touched.description ? (
                  <div className="text-danger">{errors.description}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label className="form-label">Speciality</label>
                <Select
                  name="speciality"
                  options={specialities.map((speciality) => ({
                    value: speciality._id,
                    label: speciality.titleEn,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setFieldValue("speciality", selectedOptions.value)
                  }
                />
                {errors.speciality && touched.speciality ? (
                  <div className="text-danger">{errors.speciality2}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form-label">Feature Image</label>
                <ImageDropzone
                  onFilesAdded={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setFieldValue("featureImage", acceptedFiles[0]);
                    }
                  }}
                />
                {errors.featureImage && touched.featureImage ? (
                  <div className="text-danger">{errors.featureImage}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label className="form-label">Feature Video</label>
                <VideoDropzone
                  onFilesAdded={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setFieldValue("featureVideo", acceptedFiles[0]);
                    }
                  }}
                />
                {errors.featureImage && touched.featureImage ? (
                  <div className="text-danger">{errors.featureImage}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <h5 className="mt-1"> Youtube Videos</h5>

                <FieldArray name="youtubeVideo">
                  {({ insert, remove, push }) => (
                    <div className="general-info-container">
                      {values.youtubeVideo.length > 0 &&
                        values.youtubeVideo.map((info, index) => (
                          <div key={index}>
                            <Row>
                              <Col>
                                <BootstrapForm.Group
                                  controlId={`youtubeVideo.${index}.title`}
                                  className="mb-3"
                                >
                                  <Field
                                    placeholder="Enter video title"
                                    name={`youtubeVideo.${index}.title`}
                                    className="form-control"
                                  />
                                </BootstrapForm.Group>
                                <BootstrapForm.Group
                                  controlId={`youtubeVideo.${index}.video`}
                                  className="mb-3"
                                >
                                  <Field
                                    placeholder="Enter video link"
                                    name={`youtubeVideo.${index}.video`}
                                    className="form-control"
                                  />
                                </BootstrapForm.Group>
                              </Col>

                              <Col>
                                <span
                                  class="badge badge-center rounded-pill bg-danger"
                                  onClick={() => remove(index)}
                                >
                                  {" "}
                                  <i class="ti ti-x ti-xs"></i>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      <div class="mb-2">
                        <Button
                          size="sm"
                          className="btn btn-primary waves-effect waves-light"
                          variant="primary"
                          onClick={() => push({ video: "" })}
                        >
                          <i className="ti ti-plus ti-xs me-2"></i>
                          <span class="align-middle">Add</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
              <div className="mb-3">
                <label className="form-label">Status</label>
                <Field className="form-control" as="select" name="status">
                  <option value="">Select Status</option>
                  <option value="published">Published</option>
                  <option value="pending">Pending</option>
                  <option value="draft">Draft</option>
                </Field>
                {errors.status && touched.status ? (
                  <div className="text-danger">{errors.status}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <h5 className="card-header">SEO</h5>

            <div class="card-body">
              <div className="mb-3">
                <label className="form-label">Keywords</label>
                <Field className="form-control" name="seo.keywords" />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <Field className="form-control" name="seo.description" />
              </div>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <Field className="form-control" name="seo.title" />
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <h5 className="card-header">Doctor Details</h5>

            <div class="card-body">
              <div className="mb-3">
                <label className="form-label">Telephone</label>
                <Field className="form-control" name="telephone" />
              </div>
              <div className="mb-3">
                <label className="form-label">Fax</label>
                <Field className="form-control" name="fax" />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <Field className="form-control" name="email" />
              </div>
              <div className="mb-3">
                <label className="form-label">Address</label>
                <Field className="form-control" name="address" />
              </div>
              <div className="mb-3">
                <label className="form-label">Landmark</label>
                <Field className="form-control" name="landmark" />
              </div>

              <div className="mb-3">
                <label className="form-label">External Link</label>
                <Field
                  className="form-control"
                  name="doctorDetails.externalLink"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Doctor Profile Link</label>
                <Field
                  className="form-control"
                  name="doctorDetails.doctorProfileLink"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Book Now Form</label>
                <Field
                  className="form-control"
                  name="doctorDetails.bookNowForm"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Short Description</label>
                <Field
                  className="form-control"
                  name="doctorDetails.shortDescription"
                  as="textarea"
                />
              </div>

              <div className="mb-3">
                <h5 className="mt-1"> General Information</h5>

                <FieldArray name="doctorDetails.generalInformation">
                  {({ insert, remove, push }) => (
                    <div className="general-info-container">
                      <Row>
                        <Col>
                          <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>Title</BootstrapForm.Label>
                          </BootstrapForm.Group>
                        </Col>
                        <Col>
                          <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>
                              Description
                            </BootstrapForm.Label>
                          </BootstrapForm.Group>
                        </Col>
                        <Col></Col>
                      </Row>

                      {values.doctorDetails.generalInformation.length > 0 &&
                        values.doctorDetails.generalInformation.map(
                          (info, index) => (
                            <div key={index}>
                              <Row>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`doctorDetails.generalInformation.${index}.title`}
                                    className="mb-3"
                                  >
                                    <Field
                                      placeholder="Enter Title"
                                      name={`doctorDetails.generalInformation.${index}.title`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`doctorDetails.generalInformation.${index}.content`}
                                    className="mb-3"
                                  >
                                    <Editor
                                      apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API Key
                                      initialValue={""} // Initial empty value
                                      onEditorChange={(content) =>
                                        setFieldValue(
                                          `doctorDetails.generalInformation.${index}.content`,
                                          content
                                        )
                                      }
                                      init={{
                                        height: 400,
                                        menubar: false,
                                        directionality: "ltr",
                                        language: "en",
                                        language_url: "",
                                        plugins: [
                                          "advlist autolink lists link image charmap print preview anchor",
                                          "searchreplace visualblocks code fullscreen",
                                          "insertdatetime media table paste code help wordcount",
                                        ],
                                        toolbar:
                                          "undo redo | styles | formatselect | " +
                                          "bold italic underline strikethrough | " +
                                          "alignleft aligncenter alignright alignjustify | " +
                                          "bullist numlist outdent indent | " +
                                          "link image media table blockquote | selectall",
                                        style_formats: [
                                          { title: "Heading 1", block: "h1" },
                                          { title: "Heading 2", block: "h2" },
                                          { title: "Heading 3", block: "h3" },
                                          { title: "Heading 4", block: "h4" },
                                          { title: "Heading 5", block: "h5" },
                                          { title: "Heading 6", block: "h6" },
                                          { title: "Paragraph", block: "p" },
                                          {
                                            title: "Blockquote",
                                            block: "blockquote",
                                          },
                                          { title: "Code", block: "code" },
                                        ],
                                        content_style:
                                          "body { font-family: Arial, sans-serif; font-size: 14px; }",
                                        branding: false,
                                      }}
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <span
                                    class="badge badge-center rounded-pill bg-danger"
                                    onClick={() => remove(index)}
                                  >
                                    {" "}
                                    <i class="ti ti-x ti-xs"></i>
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          )
                        )}
                      <div class="mb-2">
                        <Button
                          size="sm"
                          className="btn btn-primary waves-effect waves-light"
                          variant="primary"
                          onClick={() => push({ title: "", content: "" })}
                        >
                          <i className="ti ti-plus ti-xs me-2"></i>
                          <span class="align-middle">Add</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className="mb-3">
                <label className="form-label">Research Articles</label>

                <ImageDropzone
                  onFilesAdded={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setFieldValue(
                        "doctorDetails.researchArticles",
                        acceptedFiles[0]
                      );
                    }
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Date of Joining</label>
                <br />

                <Field
                  name="doctorDetails.dateOfJoining"
                  component={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(val) => setFieldValue(field.name, val)}
                      className="form-control"
                    />
                  )}
                />
              </div>

              <div className="mb-3">
                <h5 className="mt-1"> Timings</h5>

                <FieldArray name="doctorDetails.timings">
                  {({ insert, remove, push }) => (
                    <div className="clinic-container">
                      {values.doctorDetails.timings.length > 0 &&
                        values.doctorDetails.timings.map((timing, index) => (
                          <>
                            <div key={index}>
                              <div className="mb-3">
                                <label className="form-label">Clinic</label>
                                <Select
                                  name={`doctorDetails.timings.${index}.clinic`}
                                  options={clinics.map((clinic) => ({
                                    value: clinic._id,
                                    label: clinic.title,
                                  }))}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(selectedOptions) =>
                                    setFieldValue(
                                      `doctorDetails.timings.${index}.clinic`,
                                      selectedOptions.value
                                    )
                                  }
                                />
                              </div>
                              <div className="mb-3">
                                <FieldArray
                                  name={`doctorDetails.timings.${index}.timing`}
                                >
                                  {({ insert, remove, push }) => (
                                    <div className="clinic-timings">
                                      <Row>
                                        <Col>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              Day
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              From Time (First Half)
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              To Time (First Half)
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              From Time (Second Half)
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              To Time (Second Half)
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              Off Day
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                      {timing.timing.length > 0 &&
                                        timing.timing.map((time, idx) => (
                                          <div key={idx}>
                                            <Row>
                                              <Col>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.day`}
                                                  className="mb-3"
                                                >
                                                  <Field
                                                    name={`doctorDetails.timings.${index}.timing.${idx}.day`}
                                                    as="select"
                                                    className="form-control"
                                                  >
                                                    <option value="">
                                                      Select Day
                                                    </option>
                                                    <option value="Sunday">
                                                      Sunday
                                                    </option>
                                                    <option value="Monday">
                                                      Monday
                                                    </option>
                                                    <option value="Tuesday">
                                                      Tuesday
                                                    </option>
                                                    <option value="Wednesday">
                                                      Wednesday
                                                    </option>
                                                    <option value="Thursday">
                                                      Thursday
                                                    </option>
                                                    <option value="Friday">
                                                      Friday
                                                    </option>
                                                    <option value="Saturday">
                                                      Saturday
                                                    </option>
                                                  </Field>
                                                </BootstrapForm.Group>
                                              </Col>
                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.fromTimeFirstHalf`}
                                                  className="mb-3"
                                                >
                                                  <TimePicker
                                                    format="h:m a"
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `doctorDetails.timings.${index}.timing.${idx}.fromTimeFirstHalf`,
                                                        value
                                                      )
                                                    }
                                                    value={
                                                      values.doctorDetails
                                                        .timings[index].timing[
                                                        idx
                                                      ].fromTimeFirstHalf
                                                    }
                                                  />
                                                </BootstrapForm.Group>
                                              </Col>

                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.toTimeFirstHalf`}
                                                  className="mb-3"
                                                >
                                                  <TimePicker
                                                    format="h:m a"
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `doctorDetails.timings.${index}.timing.${idx}.toTimeFirstHalf`,
                                                        value
                                                      )
                                                    }
                                                    value={
                                                      values.doctorDetails
                                                        .timings[index].timing[
                                                        idx
                                                      ].toTimeFirstHalf
                                                    }
                                                  />
                                                </BootstrapForm.Group>
                                              </Col>
                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.fromTimeSecondHalf`}
                                                  className="mb-3"
                                                >
                                                  <TimePicker
                                                    format="h:m a"
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `doctorDetails.timings.${index}.timing.${idx}.fromTimeSecondHalf`,
                                                        value
                                                      )
                                                    }
                                                    value={
                                                      values.doctorDetails
                                                        .timings[index].timing[
                                                        idx
                                                      ].fromTimeSecondHalf
                                                    }
                                                  />
                                                </BootstrapForm.Group>
                                              </Col>
                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.toTimeSecondHalf`}
                                                  className="mb-3"
                                                >
                                                  <TimePicker
                                                    format="h:m a"
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `doctorDetails.timings.${index}.timing.${idx}.toTimeSecondHalf`,
                                                        value
                                                      )
                                                    }
                                                    value={
                                                      values.doctorDetails
                                                        .timings[index].timing[
                                                        idx
                                                      ].toTimeSecondHalf
                                                    }
                                                  />
                                                </BootstrapForm.Group>
                                              </Col>

                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.offDay`}
                                                  className="mb-3"
                                                >
                                                  <div className="col-12 d-flex align-items-center gap-2">
                                                    <div className="form-check">
                                                      <Field
                                                        name={`doctorDetails.timings.${index}.timing.${idx}.offDay`}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                      />
                                                      <label className="form-check-label">
                                                        {" "}
                                                        Yes{" "}
                                                      </label>
                                                    </div>
                                                    <span
                                                      class="badge badge-center rounded-pill bg-danger"
                                                      onClick={() =>
                                                        remove(idx)
                                                      }
                                                    >
                                                      {" "}
                                                      <i class="ti ti-x ti-xs"></i>
                                                    </span>
                                                  </div>
                                                </BootstrapForm.Group>
                                              </Col>
                                            </Row>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                              }}
                                            >
                                              OR
                                            </div>
                                            <div className="mb-3">
                                              <label className="form-label">
                                                Custom data for day
                                              </label>
                                              <Field
                                                placeholder="Enter custom data for day"
                                                className="form-control"
                                                name={`doctorDetails.timings.${index}.timing.${idx}.day`}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      <Button
                                        size="sm"
                                        className="btn btn-primary waves-effect waves-light mb-2"
                                        variant="primary"
                                        onClick={() =>
                                          push({
                                            day: "",
                                            fromTimeFirstHalf: "",
                                            toTimeFirstHalf: "",
                                            fromTimeSecondHalf: "",
                                            toTimeSecondHalf: "",
                                            offDay: false,
                                          })
                                        }
                                      >
                                        Add Timing
                                      </Button>
                                    </div>
                                  )}
                                </FieldArray>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  className=" btn btn-danger waves-effect waves-light"
                                  type="button"
                                  onClick={() => remove(index)}
                                >
                                  Remove Clinic
                                </Button>
                              </div>
                            </div>
                            <hr className="my-6 mx-n4" />
                          </>
                        ))}

                      <Button
                        size="sm"
                        className="btn btn-primary waves-effect waves-light"
                        variant="primary"
                        onClick={() =>
                          push({
                            clinic: "",
                            timing: [
                              {
                                day: "",
                                fromTimeFirstHalf: "",
                                toTimeFirstHalf: "",
                                fromTimeSecondHalf: "",
                                toTimeSecondHalf: "",
                                offDay: false,
                              },
                            ],
                          })
                        }
                      >
                        <i className="ti ti-plus ti-xs me-2"></i>
                        <span class="align-middle">Add Clinic</span>
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className="mb-3">
                <label className="form-label">Availibilty Status</label>
                <Field
                  className="form-control"
                  as="select"
                  name="doctorDetails.status"
                >
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Field>
              </div>
              <div className="mb-3">
                <label className="form-label">Clinics</label>
                <Select
                  name="doctorDetails.clinics"
                  options={clinics.map((clinic) => ({
                    value: clinic._id,
                    label: clinic.title,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setFieldValue(
                      "doctorDetails.clinics",
                      selectedOptions.value
                    )
                  }
                />
              </div>
              <div className="mb-3">
                {" "}
                <label className="form-label">Best Doctor</label>
                <div className="form-check">
                  <Field
                    name="doctorDetails.bestDoctor"
                    type="checkbox"
                    className="form-check-input"
                  />
                  <label className="form-check-label"> Yes </label>
                </div>
              </div>
              <div className="mb-3">
                {/* <BootstrapForm.Group controlId="linkedBlog" className="mb-3">
                  <BootstrapForm.Label>Blogs</BootstrapForm.Label>

                  <Select
                    isMulti
                    name="linkedBlog"
                    options={blogs?.map((blog) => ({
                      value: blog?._id,
                      label: blog?.en_title,
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={values.linkedBlog
                      .map((blogId) =>
                        blogs.find((blog) => blog._id === blogId)
                          ? {
                              value: blogId,
                              label: blogs.find((blog) => blog._id === blogId)
                                .en_title,
                            }
                          : null
                      )
                      .filter(Boolean)}
                    onChange={(selectedOptions) =>
                      setFieldValue(
                        "linkedBlog",
                        selectedOptions.map((option) => option.value)
                      )
                    }
                  />
                </BootstrapForm.Group> */}
                <BootstrapForm.Group controlId="linkedBlog" className="mb-3">
                  <BootstrapForm.Label>Add Blogs</BootstrapForm.Label>
                  <Select
                    isMulti
                    name="linkedBlog"
                    options={blogs?.map((blog) => ({
                      value: blog._id,
                      label: blog.en_title,
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOptions) =>
                      setFieldValue("linkedBlog", selectedOptions)
                    }
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group controlId="linkedNews" className="mb-3">
                  <BootstrapForm.Label>Add News</BootstrapForm.Label>
                  <Select
                    isMulti
                    name="linkedNews"
                    options={news?.map((news) => ({
                      value: news._id,
                      label: news.en_title,
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOptions) =>
                      setFieldValue("linkedNews", selectedOptions)
                    }
                  />
                </BootstrapForm.Group>
              </div>
              <div className="mb-3">
                <label className="form-label">Logo Image</label>
                <ImageDropzone
                  onFilesAdded={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setFieldValue(
                        "doctorDetails.logoImage",
                        acceptedFiles[0]
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div class="card mb-6 mb-3">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="mb-0 card-title">Testimonials</h5>
            </div>
            <div class="card-body">
              <FieldArray name="testimonials">
                {({ insert, remove, push }) => (
                  <div className="testimonial-container">
                    <Row>
                      <Col>
                        <BootstrapForm.Group className="mb-3">
                          <BootstrapForm.Label>Image</BootstrapForm.Label>
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group className="mb-3">
                          <BootstrapForm.Label>Title</BootstrapForm.Label>
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group className="mb-3">
                          <BootstrapForm.Label>Testimonial</BootstrapForm.Label>
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group className="mb-3">
                          <BootstrapForm.Label>Address</BootstrapForm.Label>
                        </BootstrapForm.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                    {values?.testimonials?.map((testimonial, index) => (
                      <div key={index}>
                        <Row>
                          <Col>
                            <BootstrapForm.Group
                              controlId={`testimonials.${index}.image`}
                              className="mb-3"
                            >
                              <div className="drop-testimonial">
                                <ImageDropzone
                                  onFilesAdded={(acceptedFiles) => {
                                    if (acceptedFiles.length > 0) {
                                      console.log(acceptedFiles[0]);
                                      setFieldValue(
                                        `testimonials.${index}.image`,
                                        acceptedFiles[0]
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </BootstrapForm.Group>
                          </Col>
                          <Col>
                            <BootstrapForm.Group
                              controlId={`testimonials.${index}.title`}
                              className="mb-3"
                            >
                              <Field
                                placeholder="Enter Title"
                                name={`testimonials.${index}.title`}
                                className="form-control"
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col>
                            <BootstrapForm.Group
                              controlId={`testimonials.${index}.testimonial`}
                              className="mb-3"
                            >
                              <Field
                                placeholder="Enter Testimonial"
                                name={`testimonials.${index}.testimonial`}
                                className="form-control"
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col>
                            <BootstrapForm.Group
                              controlId={`testimonials.${index}.address`}
                              className="mb-3"
                            >
                              <Field
                                placeholder="Enter Address"
                                name={`testimonials.${index}.address`}
                                className="form-control"
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col>
                            <span
                              class="badge badge-center rounded-pill bg-danger"
                              onClick={() => remove(index)}
                            >
                              {" "}
                              <i class="ti ti-x ti-xs"></i>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <div class="mb-2">
                      <Button
                        size="sm"
                        className="btn btn-primary waves-effect waves-light"
                        variant="primary"
                        onClick={() =>
                          push({
                            image: "",
                            title: "",
                            testimonial: "",
                            address: "",
                          })
                        }
                      >
                        <i className="ti ti-plus ti-xs me-2"></i>
                        <span class="align-middle">Add</span>
                      </Button>
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <Button
                className="btn btn-success waves-effect waves-light"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EnAddDoctorForm;
