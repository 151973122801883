// export const url = 'http://localhost:5500';
// export const assetUrl = 'http://localhost:5500'
// export const Image = 'http://localhost:5500/uploads/'
// export const BASE_URL = 'http://localhost:5500/'

 //export const url = "https://admin.asterclinic.ae:3000";
 //export const assetUrl = "https://admin.asterclinic.ae.com:3000";
 //export const Image = "https://admin.asterclinic.ae:3000/uploads";
 //export const BASE_URL = "https://admin.asterclinic.ae:3000/";

//************
export const url = "https://asteradmin.stldigitaltech.com:3000";
export const assetUrl = "https://asteradmin.stldigitaltech.com.com:3000";
export const Image = "https://asteradmin.stldigitaltech.com:3000/uploads";
export const BASE_URL = "https://asteradmin.stldigitaltech.com:3000/";

export const USER_DOMAIN_URL = "https://asterclinicmern.stldigitaltech.com";
//*************

// export const url = "http://localhost:3010";
// export const assetUrl = "http://localhost:3010";
// export const Image = "http://localhost:3010/uploads";
// export const BASE_URL = "http://localhost:3010/";

// export const USER_DOMAIN_URL = "https://asterclinicmern.stldigitaltech.com";

// export const USER_DOMAIN_URL = "https://asterclinic.ae"
