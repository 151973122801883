import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../Components/Common/Url";
import DataTable from "react-data-table-component";
import { formatDate } from "../utils/common";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const generateColumns = (formData) => {
  if (!formData || formData.length === 0) return [];

  // Get all possible keys
  const allKeys = Array.from(
    new Set(formData.flatMap((obj) => Object.keys(obj)))
  ).filter((k) => k !== "__v" && k !== "_id"); // Exclude unwanted keys

  return allKeys.map((key) => {
    const rowKey =
      key === "createdAt" || key === "updatedAt"
        ? (row) => formatDate(row[key]) || "-"
        : (row) => row[key] || "-";
    return {
      name: key.charAt(0).toUpperCase() + key.slice(1),
      selector: rowKey,
      sortable: true,
    };
  });
};

const LeadBookNow = () => {
  const [clinics, setClinics] = useState([]);
  const [clinicData, setClinicData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState("");

  useEffect(() => {
    const fetchClinics = async () => {
      try {
        const { data } = await axios.get(`${url}/api/v2/listClinicsWithForms`);
        if (Array.isArray(data)) {
          setClinics(data);
        }
      } catch (error) {
        console.error("Unable to fetch clinics:", error.message);
      }
    };
    fetchClinics();
  }, []);

  const getClinicDataByName = async (clinicName) => {
    if (!clinicName) return;
    try {
      const { data } = await axios.get(
        `${url}/api/v2/listBookNow/${clinicName}`
      );
      if (Array.isArray(data)) {
        setClinicData(data);
        setColumns(generateColumns(data));
      }
    } catch (e) {
      console.error("Error fetching clinic data:", e.message);
    }
  };

  const exportToExcel = () => {
    if (!clinicData.length) return;

    const formattedData = clinicData.map((entry, index) => {
      let row = { "#": index + 1 };
      columns.forEach((col) => {
        row[col.name] = col.selector(entry) || "-";
      });
      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lead Forms");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "LeadForms.xlsx");
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">
              Lead Book Now{" "}
              <button
                disabled={!clinicData.length}
                onClick={exportToExcel}
                className="btn btn-primary float-end"
              >
                Export to Excel
              </button>
            </h5>

            <div className="card-body">
              <select
                className="form-control mb-3"
                value={selectedClinic}
                onChange={(e) => {
                  const clinicName = e.target.value;
                  setSelectedClinic(clinicName);
                  getClinicDataByName(clinicName);
                }}
              >
                <option value="" disabled>
                  Select Clinic
                </option>
                {clinics.map((clinic, i) => (
                  <option key={i} value={clinic}>
                    {clinic}
                  </option>
                ))}
              </select>

              <DataTable
                title=""
                columns={columns}
                data={clinicData}
                pagination
                highlightOnHover
                responsive
                defaultSortFieldId={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadBookNow;
