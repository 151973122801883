import React, { useEffect, useState } from "react";
import FormServices from "../../Services/FormServices";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FilterComponent from "../../Components/Common/FilterComponent";

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const data = await FormServices.fetchUsers();
      setUsers(data.users);
      setFilteredUsers(data.users);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filteredData = users.filter((user) => {
      const name = user.name || "";
      const email = user.email || "";
      return name.includes(filterText) || email.includes(filterText);
    });
    setFilteredUsers(filteredData);
  }, [filterText, users]);

  const handleEdit = (id) => {
    navigate(`/admin/edit-user/${id}`);
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmed) return;

    toast.dismiss();
    try {
      const response = await FormServices.deleteUser(id);
      if (response.message) {
        toast.success(response.message);
        fetchUsers();
      } else {
        throw new Error("Unexpected response from server");
      }
    } catch (err) {
      console.error("Delete Error:", err);
      toast.error(err?.response?.data?.message || "Error deleting user");
    }
  };

  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };

  const handleClear = () => {
    setFilterText("");
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const getAdminBadge = (isAdmin) => {
    return isAdmin ? (
      <span className="badge bg-label-success me-1">Admin</span>
    ) : (
      <span className="badge bg-label-secondary me-1">User</span>
    );
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      cell: (row) => getAdminBadge(row.Creator),
    },
    {
      name: "Permissions",
      cell: (row) => (
        <div>
          {row.canAdd && <span className="badge bg-label-info me-1">Add</span>}
          {row.canEdit && (
            <span className="badge bg-label-warning me-1">Edit</span>
          )}
          {row.canDelete && (
            <span className="badge bg-label-danger me-1">Delete</span>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm me-3"
            onClick={() => handleEdit(row._id)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDelete(row._id)}
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">Users List</h5>
            <div className="card-body">
              <FilterComponent
                filterText={filterText}
                onFilter={handleFilter}
                onClear={handleClear}
              />
              <DataTable
                columns={columns}
                data={filteredUsers}
                pagination
                highlightOnHover
                striped
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
