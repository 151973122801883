import React, { useEffect, useState } from "react";
import FormServices from "../../Services/FormServices"; // Adjust the import path as needed
import DataTable from "react-data-table-component";
import { toast } from "react-toastify"; // Optional for notifications
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import FilterComponent from "../../Components/Common/FilterComponent";
// Import the filter component

const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const fetchDoctors = async () => {
    setLoading(true);
    try {
      const data = await FormServices.fetchDoctors();
      setDoctors(data);
      setFilteredDoctors(data); // Initialize filteredDoctors
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDoctors();
  }, []);

  useEffect(() => {
    const filteredData = doctors.filter((doctor) => {
      const titleEnglish = doctor.en?.title?.toLowerCase() || "";
      const titleArabic = doctor.ar?.title?.toLowerCase() || "";
      return (
        titleEnglish.includes(filterText.toLowerCase()) ||
        titleArabic.includes(filterText.toLowerCase())
      );
    });
    setFilteredDoctors(filteredData);
  }, [filterText, doctors]);

  const handleEdit = (id) => {
    navigate(`/admin/edit-doctor/${id}`); // Use navigate instead of history.push
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this doctor?"
    );
    if (confirmed) {
      toast.dismiss();
      try {
        await FormServices.deleteDoctor(id);
        fetchDoctors();
        toast.success("Doctor deleted successfully");
      } catch (err) {
        toast.error("Error deleting doctor");
      }
    }
  };

  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };

  const handleClear = () => {
    setFilterText("");
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const getStatusBadge = (status) => {
    if (status === "published") {
      return <span className="badge bg-label-primary me-1">Published</span>;
    } else if (status === "pending") {
      return <span className="badge bg-label-warning me-1">Pending</span>;
    } else if (status === "draft") {
      return <span className="badge bg-label-info me-1">Draft</span>;
    } else {
      return null;
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title(English)",
      selector: (row) => row?.en?.title,
      sortable: true,
    },
    {
      name: "Title(Arabic)",
      selector: (row) => row?.ar?.title,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => getStatusBadge(row.en.status),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm me-3"
            onClick={() => handleEdit(row._id)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDelete(row._id)}
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">Doctors List</h5>
            <div className="card-body">
              <FilterComponent
                filterText={filterText}
                onFilter={handleFilter}
                onClear={handleClear}
              />
              <DataTable
                columns={columns}
                data={filteredDoctors}
                pagination
                highlightOnHover
                striped
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorsList;
