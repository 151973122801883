import React, { useEffect, useState } from "react";
import "./Form.css";
import { ToastContainer, toast } from "react-toastify";
import "../i18n";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ToggleButton from "../Components/toggle-button/ToggleButton";
import {
  MdDragIndicator,
  MdTextFields,
  MdDescription,
  MdOutlineTextFields,
  MdNumbers,
  MdMobileFriendly,
  MdFlag,
  MdEmail,
  MdPhotoCameraBack,
  MdOutlineFileUpload,
  MdOutlineSmartButton,
  MdOutlineClose,
  MdOutlineRadioButtonChecked,
  MdOutlineArrowDropDownCircle,
  MdOutlineDateRange,
  MdOutlineTimer,
  MdOutlineRemoveRedEye,
  MdOutlineCheckBox,
  MdAdd,
  MdCompareArrows,
} from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import Preview from "./Preview";
import { url } from "../Components/Common/Url";
import { useSelector } from "react-redux";

const COMPONENTS = [
  {
    id: "heading",
    heading: "Heading",
    label: "Heading",
    type: "heading",
    icon: <MdTextFields />,
  },
  {
    id: "description",
    heading: "Description",
    label: "Description",
    type: "description",
    icon: <MdDescription />,
  },
  {
    id: "text",
    heading: "Text",
    label: "Text",
    type: "text",
    required: false,
    icon: <MdOutlineTextFields />,
  },
  {
    id: "textarea",
    heading: "Textarea",
    label: "Textarea",
    type: "textarea",
    required: false,
    icon: <MdDescription />,
  },
  {
    id: "number",
    heading: "Number",
    label: "Number",
    type: "number",
    required: false,
    icon: <MdNumbers />,
  },
  {
    id: "range",
    heading: "Range",
    label: "Range",
    type: "range",
    required: false,
    icon: <MdCompareArrows />,
  },
  {
    id: "mobile",
    heading: "Mobile",
    label: "Mobile",
    type: "mobile",
    required: false,
    icon: <MdMobileFriendly />,
  },
  {
    id: "email",
    heading: "Email",
    label: "Email",
    type: "email",
    required: false,
    icon: <MdEmail />,
  },
  {
    id: "file",
    heading: "File Upload",
    label: "File Upload",
    type: "file",
    required: false,
    icon: <MdOutlineFileUpload />,
  },
  {
    id: "radio",
    heading: "Radio Button",
    label: "Radio Button",
    type: "radio",
    required: false,
    icon: <MdOutlineRadioButtonChecked />,
  },
  {
    id: "dropdown",
    heading: "Dropdown",
    label: "Dropdown",
    type: "dropdown",
    required: false,
    icon: <MdOutlineArrowDropDownCircle />,
  },
  {
    id: "country",
    heading: "Country",
    label: "Country",
    type: "country",
    required: false,
    icon: <MdFlag />,
  },
  {
    id: "image",
    heading: "Image",
    label: "Image",
    type: "image",
    required: false,
    icon: <MdPhotoCameraBack />,
  },
  {
    id: "date",
    heading: "Date",
    label: "Date",
    type: "date",
    required: false,
    icon: <MdOutlineDateRange />,
  },
  {
    id: "time",
    heading: "Time",
    label: "Time",
    type: "time",
    required: false,
    icon: <MdOutlineTimer />,
  },
  {
    id: "checkbox",
    heading: "CheckBox",
    label: "CheckBox",
    type: "checkbox",
    required: false,
    icon: <MdOutlineCheckBox />,
  },
  {
    id: "button",
    heading: "Button",
    label: "Button",
    type: "button",
    icon: <MdOutlineSmartButton />,
  },
];

const CreateFormAR = () => {
  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [droppedItems, setDroppedItems] = useState([]);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [formData, setFormData] = useState({
    formName: "",
    buttonText: { en: "Submit", ar: "إرسال" },
    heading: {
      en: "Heading",
      ar: "العنوان",
    },
    headingFontSize: 26,
    description: {
      en: "Description",
      ar: "الوصف",
    },
    checkbox: "",
    text: {
      heading: {
        en: "Text",
        ar: "نص",
      },
      value: "",
      required: false,
    },
    textarea: {
      heading: {
        en: "Details",
        ar: "تفاصيل",
      },
      value: "",
      required: false,
    },
    number: {
      heading: {
        en: "Number",
        ar: "رقم",
      },
      value: "",
      required: false,
    },
    range: {
      heading: {
        en: "Range",
        ar: "مدى",
      },
      minValue: 0,
      maxValue: 0,
      value: "",
      required: false,
    },
    mobile: {
      heading: {
        en: "Mobile",
        ar: "موبايل",
      },
      countryCode: "",
      value: "",
      required: false,
    },
    email: {
      heading: {
        en: "Email",
        ar: "البريد الإلكتروني",
      },
      value: "",
      required: false,
    },
    file: {
      heading: {
        en: "Upload a file",
        ar: "رفع ملف",
      },
      value: null,
      required: false,
    },
    image: {
      heading: {
        en: "Upload Image",
        ar: "رفع صورة",
      },
      value: null,
      required: false,
    },
    dropdown: {
      heading: {
        en: "Select",
        ar: "اختر",
      },
      value: null,
      required: false,
      dropdownOptions: [],
    },
    country: {
      heading: {
        en: "Select Country",
        ar: "اختر الدولة",
      },
      selectedCountry: "",
      required: false,
    },
    radio: {
      heading: {
        en: "Choose an option",
        ar: "اختر خيارًا",
      },
      value: "",
      required: false,
      radioOptions: [],
    },
    date: {
      heading: {
        en: "Date",
        ar: "التاريخ",
      },
      value: "",
      required: false,
    },
    time: {
      heading: {
        en: "Time",
        ar: "الوقت",
      },
      value: "",
      required: false,
    },
    checkboxGroup: [],
    to: [],
    cc: [],
    thankyou_url: "",
  });
  const [forms, setForms] = useState([]);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [pages, setPages] = useState([]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
    document.documentElement.dir = lng === "Arabic" ? "rtl" : "ltr";
  };

  const fetchForms = async () => {
    try {
      const formResponse = await axios.get(`${url}/api/v1/form-builder/list`);
      if (formResponse.data) {
        setForms(formResponse.data);
      }
    } catch (error) {
      console.error(
        "Unable to fetch forms. Please try again later.",
        error.message
      );
    }
  };

  const handleChange = (field, value) => {
    const keys = field.split(".");

    setFormData((prevState) => {
      const newState = { ...prevState };
      let temp = newState;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          if (key === "dropdownOptions" || key === "radioOptions") {
            const [componentKey, optionId] = field.split(".");
            const component = temp[componentKey];

            if (component) {
              component[key] = (component[key] || []).map((option) =>
                option.id === parseInt(optionId)
                  ? { ...option, text: value }
                  : option
              );

              setDroppedItems((prevDroppedItems) =>
                prevDroppedItems.map((item) =>
                  item.id === componentKey
                    ? {
                        ...item,
                        [key]: component[key],
                      }
                    : item
                )
              );
            }
          } else {
            temp[key] = value;

            if (key === "heading" || key === "required") {
              const fieldType = field.split(".")[0];

              setDroppedItems((prevDroppedItems) =>
                prevDroppedItems.map((item) =>
                  item.id === fieldType
                    ? {
                        ...item,
                        [key]: value,
                      }
                    : item
                )
              );
            }
          }
        } else {
          temp[key] = temp[key] || {};
          temp = temp[key];
        }
      });

      return newState;
    });
  };

  const togglePreview = () => {
    setIsPreviewVisible(!isPreviewVisible);
  };

  const handleDragStart = (event, component) => {
    const data = JSON.stringify(component);
    if (data) {
      event.dataTransfer.setData("component", data);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("component");
    if (data) {
      try {
        const component = JSON.parse(data);
        const uniqueId = `${component.id}-${Date.now()}`;

        const newItem = {
          ...component,
          id: uniqueId,
          heading: component.heading || "Default Heading",
        };

        setDroppedItems((prev) => [...prev, newItem]);

        setFormData((prevData) => ({
          ...prevData,
          [component.type]: {
            ...(prevData[component.type] || {}),
            [uniqueId]: {
              heading: component.heading || "Enter a heading",
              value:
                component.type === "file" || component.type === "image"
                  ? null
                  : "",
              required: false,
              ...(component.type === "dropdown" && { dropdownOptions: [] }),
              ...(component.type === "radio" && { radioOptions: [] }),
            },
          },
        }));
      } catch (error) {
        console.error("Error parsing dropped data:", error);
      }
    }
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const handleDelete = (id) => {
    const deletedItem = droppedItems.find((item) => item.id === id);
    if (deletedItem) {
      setFormData((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData[id];
        return updatedData;
      });
      setDroppedItems(droppedItems.filter((item) => item.id !== id));
    }
  };

  const handleDragItemStart = (index) => {
    setDraggedIndex(index);
  };

  const handleItemDrop = (event, dropIndex) => {
    event.preventDefault();
    if (draggedIndex === null || draggedIndex === dropIndex) return;

    const reorderedItems = [...droppedItems];
    const [draggedItem] = reorderedItems.splice(draggedIndex, 1);
    reorderedItems.splice(dropIndex, 0, draggedItem);

    setDroppedItems(reorderedItems);
    setDraggedIndex(null);
  };

  const countryValidationRules = {
    "+1": { minLength: 10, maxLength: 10 },
    "+44": { minLength: 11, maxLength: 11 },
    "+91": { minLength: 10, maxLength: 10 },
    "+61": { minLength: 9, maxLength: 9 },
    "+49": { minLength: 10, maxLength: 11 },
    "+33": { minLength: 9, maxLength: 9 },
    "+86": { minLength: 11, maxLength: 11 },
    "+81": { minLength: 10, maxLength: 11 },
    "+55": { minLength: 10, maxLength: 11 },
    "+27": { minLength: 10, maxLength: 10 },
    "+52": { minLength: 10, maxLength: 10 },
    "+7": { minLength: 10, maxLength: 10 },
    "+92": { minLength: 10, maxLength: 10 },
    "+65": { minLength: 8, maxLength: 8 },
    "+39": { minLength: 10, maxLength: 10 },
    "+20": { minLength: 11, maxLength: 11 },
    "+90": { minLength: 10, maxLength: 10 },
    "+54": { minLength: 10, maxLength: 10 },
    "+234": { minLength: 11, maxLength: 11 },
    "+82": { minLength: 10, maxLength: 11 },
  };

  const validateMobileNumber = (number, countryCode) => {
    const { minLength, maxLength } = countryValidationRules[countryCode] || {
      minLength: 8,
      maxLength: 11,
    };
    return number.length >= minLength && number.length <= maxLength;
  };

  const handleCreateForm = async () => {
    if (!formData.formName.trim()) {
      alert("Form name is required");
      return;
    }
    if (droppedItems.length === 0) {
      alert("You must add at least one component to the form");
      return;
    }

    setIsSaveModalOpen(true);
  };

  const handleSaveForm = async () => {
    try {
      const finalFormData = { ...formData, components: droppedItems };
      const response = await axios.post(
        `${url}/api/v1/form-builder/create`,
        finalFormData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 201) {
        setFormData({
          formName: "",
          buttonText: "Submit",
          heading: "",
          headingFontSize: 26,
          description: "",
          checkbox: "",
          text: { heading: "Text", value: "", required: false },
          textarea: { heading: "Details", value: "", required: false },
          number: { heading: "Number", value: "", required: false },
          mobile: {
            heading: "Mobile",
            countryCode: "",
            value: "",
            required: false,
          },
          email: { heading: "Email", value: "", required: false },
          file: { heading: "Upload File", value: null, required: false },
          image: { heading: "Upload Image", value: null, required: false },
          dropdown: {
            heading: "Select",
            value: null,
            required: false,
            dropdownOptions: [],
          },
          country: {
            heading: "Select Country",
            selectedCountry: "",
            required: false,
          },
          checkBox: { heading: "Check this box", selected: false, value: "" },
          radio: { heading: "Choose an option", selected: "", value: "" },
          date: { heading: "Date", value: "", required: false },
          time: { heading: "Time", value: "", required: false },
          components: [],
        });
        setDroppedItems([]);
        await fetchForms();
        closeSaveModal();
      }
    } catch (error) {
      console.error("Error creating form:", error);
    }
  };

  const closeSaveModal = () => {
    setIsSaveModalOpen(false);
  };

  const handleDeleteClick = (formName) => {
    setFormToDelete(formName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormToDelete(null);
  };

  const handleDeleteForm = async () => {
    try {
      const response = await axios.delete(
        `${url}/api/v1/form-builder/delete/${formToDelete}`
      );
      closeModal();
      if (response.status === 200) {
        await fetchForms();
      }
    } catch (error) {
      console.error("Error deleting form:", error);
      closeModal();
    }
  };

  const ListCountries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const addRadioOption = (itemId) => {
    const newOption = { id: Date.now(), text: "" };

    setFormData((prevData) => ({
      ...prevData,
      radio: {
        ...prevData.radio,
        [itemId]: {
          ...(prevData.radio?.[itemId] || {}),
          radioOptions: [
            ...(prevData.radio?.[itemId]?.radioOptions || []),
            newOption,
          ],
        },
      },
    }));

    setDroppedItems((prevDroppedItems) =>
      prevDroppedItems.map((component) =>
        component.id === itemId
          ? {
              ...component,
              radioOptions: [...(component.radioOptions || []), newOption],
            }
          : component
      )
    );
  };

  const removeRadioOption = (itemId, optionId) => {
    setFormData((prevData) => ({
      ...prevData,
      radio: {
        ...prevData.radio,
        [itemId]: {
          ...(prevData.radio?.[itemId] || {}),
          radioOptions: prevData.radio?.[itemId]?.radioOptions.filter(
            (option) => option.id !== optionId
          ),
        },
      },
    }));

    setDroppedItems((prevDroppedItems) =>
      prevDroppedItems.map((component) =>
        component.id === itemId
          ? {
              ...component,
              radioOptions: component.radioOptions.filter(
                (option) => option.id !== optionId
              ),
            }
          : component
      )
    );
  };

  const updateRadioOptionText = (itemId, optionId, newText) => {
    setFormData((prevData) => ({
      ...prevData,
      radio: {
        ...prevData.radio,
        [itemId]: {
          ...(prevData.radio?.[itemId] || {}),
          radioOptions: prevData.radio?.[itemId]?.radioOptions.map((option) =>
            option.id === optionId ? { ...option, text: newText } : option
          ),
        },
      },
    }));

    setDroppedItems((prevDroppedItems) =>
      prevDroppedItems.map((component) =>
        component.id === itemId
          ? {
              ...component,
              radioOptions: component.radioOptions.map((option) =>
                option.id === optionId ? { ...option, text: newText } : option
              ),
            }
          : component
      )
    );
  };

  const fetchPages = async () => {
    try {
      const response = await axios.get(`${url}/api/v1/setting/pages`, {
        headers: {
          token: `${token}`,
        },
      });
      setPages(response.data);
    } catch (err) {
      console.error("Error fetching pages:", err);
    }
  };

  useEffect(() => {
    fetchPages();
    fetchForms();
  }, []);

  const updateHeadingWithRange = (id, minValue, maxValue) => {
    const baseHeading = formData[id]?.heading?.split(" (")[0] || "";
    const newHeading = `${baseHeading} (${minValue}-${maxValue})`;
    handleChange(`${id}.heading`, newHeading);
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row card pt-3">
        <h3 classname="pt-3">Create New Form</h3>
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="form-container">
              <div className="app-container">
                <div className="components-panel">
                  <h3>Form Components</h3>
                  <div className="components-grid">
                    {COMPONENTS.map((component) => (
                      <div
                        key={component.id}
                        className="draggable-item"
                        draggable
                        onDragStart={(e) => handleDragStart(e, component)}
                      >
                        <div className="component-icon-label">
                          {component.icon}
                          <span>{component.label}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="created-forms-container">
                    <h3>Created Forms:</h3>
                    {forms?.map((form, index) => (
                      <div className="saved-forms" key={index}>
                        <h4 key={index}>{`${index + 1}: ${form.formName}`}</h4>
                        <FaRegTrashAlt
                          className="form-trash-icon"
                          onClick={() => handleDeleteClick(form.formName)}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="drop-container"
                  onDrop={handleDrop}
                  onDragOver={allowDrop}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <div className="drop-container-heading">
                      <div className="drop-heading-left">
                        <h4>Form Name:</h4>
                        <input
                          type="text"
                          placeholder="Enter form name..."
                          value={formData.formName}
                          onChange={(e) =>
                            handleChange("formName", e.target.value)
                          }
                          required
                        />
                      </div>
                      <div className="drop-heading-right">
                        <p onClick={togglePreview}>
                          <MdOutlineRemoveRedEye className="preview-icon" />
                          <span>Preview</span>
                        </p>
                        <button
                          className="primary-button"
                          onClick={handleCreateForm}
                        >
                          <MdAdd /> Create Form
                        </button>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <input
                        type="email"
                        name="to"
                        placeholder="Send this form notification to..."
                        value={formData.to ? formData.to.join(", ") : ""}
                        onChange={(e) => {
                          const emails = e.target.value
                            .split(",")
                            .map((email) => email.trim());
                          handleChange(e.target.name, emails);
                        }}
                      />
                      <input
                        type="email"
                        name="cc"
                        placeholder="Send this form notification to CC..."
                        value={formData.cc ? formData.cc.join(", ") : ""}
                        onChange={(e) => {
                          const emails = e.target.value
                            .split(",")
                            .map((email) => email.trim());
                          handleChange(e.target.name, emails);
                        }}
                      />
                      <select
                        className="w-100"
                        name="thankyou_url"
                        value={formData.thankyou_url}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Select a Thank You URL
                        </option>
                        {pages &&
                          pages.length > 0 &&
                          pages.map((p) => (
                            <option value={p?.slug}>
                              {p?.titleEn || p?.metaTitleEn || p?.slug}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <hr />
                  {droppedItems.length === 0 && (
                    <p className="placeholder-text">
                      Drag any component here from the left
                    </p>
                  )}

                  {droppedItems.map((item, index) => (
                    <div
                      key={item.id}
                      className="dropped-item"
                      draggable
                      onDragStart={() => handleDragItemStart(index)}
                      onDragOver={(e) => allowDrop(e)}
                      onDrop={(e) => handleItemDrop(e, index)}
                    >
                      {item.type === "text" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className={`component-group ${
                              activeLanguage === "ar" ? "rtl" : "ltr"
                            }`}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name={item.id}
                                required={formData[item.id]?.required || false}
                                value={formData[item.id]?.value || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.value`,
                                    e.target.value
                                  )
                                }
                                dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                              />
                              <label htmlFor={item.id}>
                                {formData[item.id]?.heading ||
                                  (activeLanguage === "ar" ? "نص" : "Text")}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "textarea" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className={`component-group ${
                              activeLanguage === "ar" ? "rtl" : "ltr"
                            }`}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <textarea
                                type="text"
                                name={item.id}
                                required={formData[item.id]?.required || false}
                                rows={3}
                                dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                                value={formData[item.id]?.value || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.value`,
                                    e.target.value
                                  )
                                }
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل النص"
                                    : "Enter text"
                                }
                              ></textarea>
                              <label htmlFor={item.id}>
                                {formData[item.id]?.heading ||
                                  (activeLanguage === "ar"
                                    ? "حقل النص"
                                    : "Textarea")}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "number" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className={`component-group ${
                              activeLanguage === "ar" ? "rtl" : "ltr"
                            }`}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name={item.id}
                                dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                                value={formData[item.id]?.value || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    handleChange(`${item.id}.value`, value);
                                  }
                                }}
                                required={formData[item.id]?.required || false}
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل الرقم"
                                    : "Enter number"
                                }
                              />
                              <label htmlFor={item.id}>
                                {formData[item.id]?.heading ||
                                  (activeLanguage === "ar" ? "رقم" : "Number")}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "range" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className={`component-group ${
                              activeLanguage === "ar" ? "rtl" : "ltr"
                            }`}
                          >
                            <div className="component-top-group">
                              <div style={{ display: "flex", gap: "10px" }}>
                                <input
                                  type="text"
                                  placeholder={
                                    activeLanguage === "ar"
                                      ? "أدخل العنوان"
                                      : "Enter heading"
                                  }
                                  value={formData[item.id]?.heading || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      `${item.id}.heading`,
                                      e.target.value
                                    )
                                  }
                                />
                                <input
                                  type="number"
                                  placeholder={
                                    activeLanguage === "ar"
                                      ? "الحد الأدنى"
                                      : "Min"
                                  }
                                  value={formData[item.id]?.minValue || ""}
                                  onChange={(e) => {
                                    const minValue =
                                      parseInt(e.target.value, 10) || 0;
                                    handleChange(
                                      `${item.id}.minValue`,
                                      minValue
                                    );
                                    updateHeadingWithRange(
                                      item.id,
                                      minValue,
                                      formData[item.id]?.maxValue || 0
                                    );
                                  }}
                                  style={{ width: "100px" }}
                                />
                                <input
                                  type="number"
                                  placeholder={
                                    activeLanguage === "ar"
                                      ? "الحد الأقصى"
                                      : "Max"
                                  }
                                  value={formData[item.id]?.maxValue || ""}
                                  onChange={(e) => {
                                    const maxValue =
                                      parseInt(e.target.value, 10) || 0;
                                    handleChange(
                                      `${item.id}.maxValue`,
                                      maxValue
                                    );
                                    updateHeadingWithRange(
                                      item.id,
                                      formData[item.id]?.minValue || 0,
                                      maxValue
                                    );
                                  }}
                                  style={{ width: "100px" }}
                                />
                              </div>
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="range"
                                dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                                value={formData[item.id]?.value || ""}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value, 10);
                                  const minValue =
                                    formData[item.id]?.minValue || 0;
                                  const maxValue =
                                    formData[item.id]?.maxValue || 0;
                                  if (
                                    !isNaN(value) &&
                                    value >= minValue &&
                                    value <= maxValue
                                  ) {
                                    handleChange(`${item.id}.value`, value);
                                  }
                                }}
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل النطاق"
                                    : "Enter range"
                                }
                              />
                              <label htmlFor="range">
                                {`${
                                  formData[item.id]?.heading ||
                                  (activeLanguage === "ar" ? "النطاق" : "Range")
                                }`}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "mobile" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className={`component-group ${
                              activeLanguage === "ar" ? "rtl" : "ltr"
                            }`}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <select
                                name="countryCode"
                                value={formData[item.id]?.countryCode || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.countryCode`,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">
                                  {activeLanguage === "ar"
                                    ? "حدد رمز الدولة"
                                    : "Select Country Code"}
                                </option>
                                <option value="+54">+54 (Argentina)</option>
                                <option value="+61">+61 (Australia)</option>
                                <option value="+43">+43 (Austria)</option>
                                <option value="+32">+32 (Belgium)</option>
                                <option value="+55">+55 (Brazil)</option>
                                <option value="+359">+359 (Bulgaria)</option>
                                <option value="+1-242">+1-242 (Bahamas)</option>
                                <option value="+1-876">+1-876 (Jamaica)</option>
                                <option value="+1">+1 (USA)</option>
                                <option value="+971">
                                  +971 (United Arab Emirates)
                                </option>
                                <option value="+44">+44 (UK)</option>
                                <option value="+91">+91 (India)</option>
                                <option value="+33">+33 (France)</option>
                                <option value="+49">+49 (Germany)</option>
                                <option value="+34">+34 (Spain)</option>
                                <option value="+39">+39 (Italy)</option>
                                <option value="+81">+81 (Japan)</option>
                                <option value="+7">+7 (Russia)</option>
                                <option value="+86">+86 (China)</option>
                                <option value="+20">+20 (Egypt)</option>
                                <option value="+63">+63 (Philippines)</option>
                                <option value="+90">+90 (Turkey)</option>
                                <option value="+351">+351 (Portugal)</option>
                                <option value="+48">+48 (Poland)</option>
                                <option value="+41">+41 (Switzerland)</option>
                                <option value="+46">+46 (Sweden)</option>
                                <option value="+45">+45 (Denmark)</option>
                                <option value="+64">+64 (New Zealand)</option>
                                <option value="+27">+27 (South Africa)</option>
                                <option value="+82">+82 (South Korea)</option>
                                <option value="+66">+66 (Thailand)</option>
                                <option value="+65">+65 (Singapore)</option>
                                <option value="+58">+58 (Venezuela)</option>
                                <option value="+30">+30 (Greece)</option>
                                <option value="+34">+34 (Spain)</option>
                                <option value="+56">+56 (Chile)</option>
                              </select>

                              <input
                                type="text"
                                name="mobile"
                                required
                                dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                                value={formData[item.id]?.number || ""}
                                maxLength={
                                  countryValidationRules[
                                    formData[item.id]?.countryCode
                                  ]?.maxLength || 11
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const countryCode =
                                    formData[item.id]?.countryCode;

                                  if (/^\d*$/.test(value)) {
                                    handleChange(`${item.id}.value`, value);

                                    if (
                                      countryCode &&
                                      countryValidationRules[countryCode]
                                    ) {
                                      const isValid = validateMobileNumber(
                                        value,
                                        countryCode
                                      );
                                      if (!isValid) {
                                        console.error(
                                          activeLanguage === "ar"
                                            ? "طول رقم الهاتف غير صالح للبلد المحدد"
                                            : "Invalid mobile number length for the selected country"
                                        );
                                      }
                                    }
                                  }
                                }}
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل رقم الجوال"
                                    : "Enter mobile number"
                                }
                              />
                              <label htmlFor="mobile">
                                {formData[item.id]?.heading ||
                                  (activeLanguage === "ar"
                                    ? "الجوال"
                                    : "Mobile")}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "email" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className={`component-group ${
                              activeLanguage === "ar" ? "rtl" : "ltr"
                            }`}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                required={formData[item.id]?.required}
                                dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                                value={formData[item.id]?.value || ""}
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل البريد الإلكتروني"
                                    : "Enter email address"
                                }
                                onChange={(e) => {
                                  const emailValue = e.target.value;
                                  const isValid =
                                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                      emailValue
                                    );

                                  handleChange(`${item.id}.value`, emailValue);
                                  handleChange(`${item.id}.isValid`, isValid);
                                }}
                                style={{
                                  borderColor:
                                    formData[item.id]?.isValid === false
                                      ? "red"
                                      : "",
                                }}
                              />
                              <label htmlFor="email">
                                {formData[item.id]?.heading ||
                                  (activeLanguage === "ar"
                                    ? "البريد الإلكتروني"
                                    : "Email")}
                              </label>

                              {formData[item.id]?.value &&
                                formData[item.id]?.isValid === false && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "0.85rem",
                                    }}
                                  >
                                    {activeLanguage === "ar"
                                      ? "يرجى إدخال بريد إلكتروني صالح."
                                      : "Please enter a valid email address."}
                                  </p>
                                )}
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "file" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={
                                  formData.file?.heading[activeLanguage] || ""
                                }
                                onChange={(e) => {
                                  const newHeading = e.target.value;
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    file: {
                                      ...prevData.file,
                                      heading: {
                                        ...prevData.file.heading,
                                        [activeLanguage]: newHeading,
                                      },
                                    },
                                  }));

                                  setDroppedItems((prevItems) =>
                                    prevItems.map((prevItem) =>
                                      prevItem.id === item.id
                                        ? { ...prevItem, heading: newHeading }
                                        : prevItem
                                    )
                                  );
                                }}
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData.file?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      file: {
                                        ...prevData.file,
                                        required: isRequired,
                                      },
                                    }))
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="file"
                                id={`file-${item.id}`}
                                name={`file-${item.id}`}
                                accept="*/*"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      file: {
                                        ...prevData.file,
                                        value: file,
                                      },
                                    }));
                                  }
                                }}
                              />
                              <label htmlFor={`file-${item.id}`}>
                                {formData.file?.heading[activeLanguage] ||
                                  "Upload a file"}
                              </label>

                              {formData.file?.value && (
                                <p
                                  style={{
                                    marginTop: "0.5rem",
                                    fontSize: "0.85rem",
                                    textAlign:
                                      activeLanguage === "ar"
                                        ? "right"
                                        : "left",
                                  }}
                                >
                                  {activeLanguage === "ar"
                                    ? "الملف المحدد:"
                                    : "Selected file:"}{" "}
                                  {formData.file?.value.name}
                                </p>
                              )}

                              {formData.file?.required &&
                                !formData.file?.value && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "0.85rem",
                                      marginTop: "0.5rem",
                                      textAlign:
                                        activeLanguage === "ar"
                                          ? "right"
                                          : "left",
                                    }}
                                  >
                                    {activeLanguage === "ar"
                                      ? "الملف مطلوب. الرجاء تحميل ملف."
                                      : "File is required. Please upload a file."}
                                  </p>
                                )}
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "image" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={
                                  formData.image?.[item.id]?.heading[
                                    activeLanguage
                                  ] || ""
                                }
                                onChange={(e) => {
                                  handleChange(
                                    `image.${item.id}.heading.${activeLanguage}`,
                                    e.target.value
                                  );
                                }}
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData.image?.[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `image.${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="component-image-group">
                              <div className="form-group">
                                <input
                                  type="file"
                                  id={`image-${item.id}`}
                                  name={`image-${item.id}`}
                                  accept="image/*"
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      const imageUrl =
                                        URL.createObjectURL(file);
                                      setFormData((prevData) => ({
                                        ...prevData,
                                        image: {
                                          ...prevData.image,
                                          [item.id]: {
                                            ...(prevData.image[item.id] || {}),
                                            value: file,
                                            url: imageUrl,
                                          },
                                        },
                                      }));
                                    }
                                  }}
                                />
                                <label htmlFor={`image-${item.id}`}>
                                  {formData.image?.[item.id]?.heading[
                                    activeLanguage
                                  ] ||
                                    (activeLanguage === "ar"
                                      ? "رفع صورة"
                                      : "Upload Image")}
                                </label>
                              </div>
                              <div
                                className="show-image"
                                style={{
                                  display: formData.image[item.id]?.url
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {formData.image[item.id]?.url && (
                                  <img
                                    src={formData.image[item.id].url}
                                    alt="SelectedImage"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "cover",
                                      border: "none",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "button" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل نص الزر"
                                    : "Enter button text"
                                }
                                value={
                                  formData.buttonText[activeLanguage] || ""
                                }
                                onChange={(e) =>
                                  handleChange("buttonText", {
                                    ...formData.buttonText,
                                    [activeLanguage]: e.target.value,
                                  })
                                }
                                required
                              />
                              <div className="top-right-group">
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <button className="primary-button">
                              {formData.buttonText[activeLanguage] ||
                                (activeLanguage === "ar" ? "زر" : "Button")}
                            </button>
                          </div>
                        </>
                      )}
                      {item.type === "heading" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div className="component-group">
                            <div className="component-top-group">
                              <div className="top-left-group">
                                <input
                                  type="text"
                                  placeholder="Enter heading"
                                  value={formData.heading[activeLanguage] || ""}
                                  onChange={(e) =>
                                    handleChange("heading", e.target.value)
                                  }
                                  required
                                />
                                <input
                                  type="number"
                                  placeholder="Enter Font Size"
                                  value={formData.headingFontSize}
                                  onChange={(e) =>
                                    handleChange(
                                      "headingFontSize",
                                      parseInt(e.target.value)
                                    )
                                  }
                                  required
                                  style={{ width: "100px" }}
                                />
                              </div>
                              <div className="top-right-group">
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <h1
                              style={{
                                fontSize: `${formData.headingFontSize}px`,
                              }}
                            >
                              {formData.heading[activeLanguage] ||
                                "Default Heading"}
                            </h1>
                          </div>
                        </>
                      )}
                      {item.type === "description" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <textarea
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل الوصف"
                                    : "Enter description"
                                }
                                value={
                                  formData.description[activeLanguage] || ""
                                }
                                onChange={(e) =>
                                  handleChange("description", e.target.value)
                                }
                                required
                                style={{
                                  width: "100%",
                                  marginRight: "10px",
                                  height: "100px",
                                  borderRadius: "5px",
                                  padding: "10px",
                                }}
                              ></textarea>
                              <div className="top-right-group">
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "dropdown" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className={`component-group ${
                              activeLanguage === "ar" ? "rtl" : "ltr"
                            }`}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              {(formData[item.id]?.dropdownOptions || []).map(
                                (option, index) => (
                                  <div
                                    key={option.id}
                                    className="dropdown-option-container"
                                  >
                                    <label>{index + 1}</label>
                                    <input
                                      type="text"
                                      placeholder={
                                        activeLanguage === "ar"
                                          ? `الخيار ${index + 1}`
                                          : `Option ${index + 1}`
                                      }
                                      value={option.text || ""}
                                      onChange={(e) => {
                                        const newText = e.target.value;

                                        setFormData((prevData) => ({
                                          ...prevData,
                                          [item.id]: {
                                            ...prevData[item.id],
                                            dropdownOptions: prevData[
                                              item.id
                                            ]?.dropdownOptions.map((o) =>
                                              o.id === option.id
                                                ? { ...o, text: newText }
                                                : o
                                            ),
                                          },
                                        }));
                                      }}
                                    />
                                    <MdOutlineClose
                                      className="close-option"
                                      onClick={() =>
                                        setFormData((prevData) => ({
                                          ...prevData,
                                          [item.id]: {
                                            ...prevData[item.id],
                                            dropdownOptions: prevData[
                                              item.id
                                            ]?.dropdownOptions.filter(
                                              (o) => o.id !== option.id
                                            ),
                                          },
                                        }))
                                      }
                                    />
                                  </div>
                                )
                              )}
                              <button
                                className="primary-button"
                                onClick={() => {
                                  const newOption = {
                                    id: Date.now(),
                                    text: "",
                                  };

                                  setFormData((prevData) => ({
                                    ...prevData,
                                    [item.id]: {
                                      ...prevData[item.id],
                                      dropdownOptions: [
                                        ...(prevData[item.id]
                                          ?.dropdownOptions || []),
                                        newOption,
                                      ],
                                    },
                                  }));
                                }}
                              >
                                {activeLanguage === "ar"
                                  ? "إضافة خيار"
                                  : "Add Option"}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "country" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) => {
                                  const newHeading = e.target.value;
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    [item.id]: {
                                      ...(prevData[item.id] || {}),
                                      heading: newHeading,
                                    },
                                  }));

                                  setDroppedItems((prevDroppedItems) =>
                                    prevDroppedItems.map((component) =>
                                      component.id === item.id
                                        ? { ...component, heading: newHeading }
                                        : component
                                    )
                                  );
                                }}
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [item.id]: {
                                        ...(prevData[item.id] || {}),
                                        required: isRequired,
                                      },
                                    }))
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>

                            <div className="component-bottom-group">
                              <div className="form-group">
                                <select
                                  name="country"
                                  value={
                                    formData[item.id]?.selectedCountry || ""
                                  }
                                  onChange={(e) => {
                                    const selectedCountry = e.target.value;
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [item.id]: {
                                        ...(prevData[item.id] || {}),
                                        selectedCountry: selectedCountry,
                                      },
                                    }));

                                    setDroppedItems((prevDroppedItems) =>
                                      prevDroppedItems.map((component) =>
                                        component.id === item.id
                                          ? { ...component, selectedCountry }
                                          : component
                                      )
                                    );
                                  }}
                                >
                                  <option value="">
                                    {activeLanguage === "ar"
                                      ? "اختر الدولة"
                                      : "Select Country"}
                                  </option>
                                  {ListCountries.map((country, index) => (
                                    <option key={index} value={country}>
                                      {country}
                                    </option>
                                  ))}
                                </select>
                                <label htmlFor="country">
                                  {formData[item.id]?.heading ||
                                    (activeLanguage === "ar"
                                      ? "الدولة"
                                      : "Country")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "radio" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="component-bottom-group">
                              {(formData[item.id]?.radioOptions || []).map(
                                (option, index) => (
                                  <div
                                    key={option.id}
                                    className="bottom-group-container"
                                  >
                                    <label>
                                      {activeLanguage === "ar"
                                        ? `الخيار ${index + 1}`
                                        : `Option ${index + 1}`}
                                    </label>
                                    <input
                                      type="text"
                                      placeholder={
                                        activeLanguage === "ar"
                                          ? "أدخل الخيار"
                                          : `Option ${index + 1}`
                                      }
                                      value={option.text || ""}
                                      onChange={(e) => {
                                        const newText = e.target.value;
                                        setFormData((prevData) => ({
                                          ...prevData,
                                          [item.id]: {
                                            ...prevData[item.id],
                                            radioOptions: prevData[
                                              item.id
                                            ]?.radioOptions.map((o) =>
                                              o.id === option.id
                                                ? { ...o, text: newText }
                                                : o
                                            ),
                                          },
                                        }));
                                      }}
                                    />
                                    <MdOutlineClose
                                      className="close-option"
                                      onClick={() => {
                                        setFormData((prevData) => ({
                                          ...prevData,
                                          [item.id]: {
                                            ...prevData[item.id],
                                            radioOptions: prevData[
                                              item.id
                                            ]?.radioOptions.filter(
                                              (o) => o.id !== option.id
                                            ),
                                          },
                                        }));
                                      }}
                                    />
                                  </div>
                                )
                              )}
                              <button
                                className="primary-button"
                                onClick={() => {
                                  const newOption = {
                                    id: Date.now(),
                                    text: "",
                                  };
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    [item.id]: {
                                      ...prevData[item.id],
                                      radioOptions: [
                                        ...(prevData[item.id]?.radioOptions ||
                                          []),
                                        newOption,
                                      ],
                                    },
                                  }));
                                }}
                              >
                                {activeLanguage === "ar"
                                  ? "إضافة خيار"
                                  : "Add Option"}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "date" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="date"
                                name="date"
                                required
                                value={formData.date?.value || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.value`,
                                    e.target.value
                                  )
                                }
                              />
                              <label htmlFor="date">
                                {formData[item.id]?.heading ||
                                  (activeLanguage === "ar" ? "تاريخ" : "Date")}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "time" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل العنوان"
                                    : "Enter heading"
                                }
                                value={formData[item.id]?.heading || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.heading`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="top-right-group">
                                <ToggleButton
                                  initialState={
                                    formData[item.id]?.required || false
                                  }
                                  onToggle={(isRequired) =>
                                    handleChange(
                                      `${item.id}.required`,
                                      isRequired
                                    )
                                  }
                                  language={activeLanguage}
                                />
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="time"
                                name="time"
                                required
                                value={formData.time?.value || ""}
                                onChange={(e) =>
                                  handleChange(
                                    `${item.id}.value`,
                                    e.target.value
                                  )
                                }
                              />
                              <label htmlFor="time">
                                {formData[item.id]?.heading ||
                                  (activeLanguage === "ar" ? "الوقت" : "Time")}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {item.type === "checkbox" && (
                        <>
                          <MdDragIndicator className="drag-icon" />
                          <div
                            className="component-group"
                            style={{
                              direction:
                                activeLanguage === "ar" ? "rtl" : "ltr",
                              textAlign:
                                activeLanguage === "ar" ? "right" : "left",
                            }}
                          >
                            <div className="component-top-group">
                              <input
                                type="text"
                                placeholder={
                                  activeLanguage === "ar"
                                    ? "أدخل نص الخيار"
                                    : "Enter checkbox text"
                                }
                                value={
                                  formData.checkbox?.[activeLanguage] || ""
                                }
                                onChange={(e) => {
                                  const newHeading = e.target.value;
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    checkbox: {
                                      ...prevData.checkbox,
                                      [activeLanguage]: newHeading,
                                    },
                                  }));
                                }}
                              />
                              <div className="top-right-group">
                                <FaRegTrashAlt
                                  className="trash-icon"
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {isPreviewVisible && (
                  <Preview
                    formItems={droppedItems}
                    onClose={togglePreview}
                    formData={formData}
                  />
                )}

                {isSaveModalOpen && (
                  <div className="modal-popup-overlay">
                    <div className="modal-popup">
                      <h4>Are you sure you want to save this form?</h4>
                      <div className="modal-popup-actions">
                        <button
                          onClick={handleSaveForm}
                          className="modal-btn confirm"
                        >
                          Yes
                        </button>
                        <button
                          onClick={closeSaveModal}
                          className="modal-btn cancel"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {isModalOpen && (
                  <div className="modal-popup-overlay">
                    <div className="modal-popup">
                      <h4>Are you sure you want to delete this form?</h4>
                      <div className="modal-popup-actions">
                        <button
                          onClick={handleDeleteForm}
                          className="modal-btn confirm"
                        >
                          Yes
                        </button>
                        <button
                          onClick={closeModal}
                          className="modal-btn cancel"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFormAR;
