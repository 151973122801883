import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import PdfUpload from "../PdfUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const PreventPackageForm = () => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [titleone, setTitleone] = useState("");
  const [descriptionone, setDescriptionone] = useState("");
  const [titletwo, setTitletwo] = useState("");
  const [descriptiontwo, setDescriptiontwo] = useState("");

  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setmetadescription] = useState("");
  const [metakeyword, setMetakeyword] = useState("");

  const [singleData, setSingleData] = useState({});
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    console.log(singleData);
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleExcellance = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);

    formData.append("description", description);

    formData.append("descriptionone", descriptionone);
    formData.append("descriptiontwo", descriptiontwo);
    formData.append("titleone", titleone);

    formData.append("titletwo", titletwo);

    formData.append("metatitle", metatitle);
    formData.append("metadescription", metadescription);
    formData.append("metakeyword", metakeyword);

    try {
      const { data } = await axios.post(
        `${url}/api/v1/preventhealth/add`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      const get_excellanace_data = async () => {
        const { data } = await axios.get(
          `${url}/api/v1/preventhealth/get/${id}`,
          {
            headers: {
              token: `${token}`,
            },
          }
        );
        console.log(activeLanguage);
        console.log(data);
        if (activeLanguage === "ar") {
          setTitle(data.data.ar_title);
          setTitleone(data.data.ar_package_one_title);
          setDescriptionone(data.data.ar_package_one_description);
          setTitletwo(data.data.ar_package_two_title);
          setDescriptiontwo(data.data.ar_package_two_description);
          setMetatitle(data.data.ar_meta_title);
          setmetadescription(data.data.ar_meta_description);
          setMetakeyword(data.data.ar_meta_keywords);
          setDescription(data.data.ar_description);
        } else {
          setTitle(data.data.en_title);
          setTitleone(data.data.en_package_one_title);
          setDescriptionone(data.data.en_package_one_description);
          setTitletwo(data.data.en_package_two_title);
          setDescriptiontwo(data.data.en_package_two_description);
          setMetatitle(data.data.en_meta_title);
          setmetadescription(data.data.en_meta_description);
          setMetakeyword(data.data.en_meta_keywords);
          setDescription(data.data.en_description);
        }
      };
      get_excellanace_data();
    } else {
      setTitle("");
      setTitleone("");
      setDescriptionone("");
      setTitletwo("");
      setDescriptiontwo("");
      setMetatitle("");
      setmetadescription("");
      setMetakeyword("");
      setDescription("");
    }
  }, [id, activeLanguage]);

  const handleExcellanceUpdate = async () => {
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);

    formData.append("description", description);

    formData.append("descriptionone", descriptionone);
    formData.append("descriptiontwo", descriptiontwo);
    formData.append("titleone", titleone);

    formData.append("titletwo", titletwo);

    formData.append("metatitle", metatitle);
    formData.append("metadescription", metadescription);
    formData.append("metakeyword", metakeyword);

    try {
      const { data } = await axios.put(
        `${url}/api/v1/preventhealth/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="col-lg-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div>
              <div className="row g-3">
                <div className="col-md-12">
                  <label className="form-label">{t("Title")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Description")}</label>
                  <Editor
                    key={`editor1-${activeLanguage}`}
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                    value={description} // Set the current content
                    init={{
                      height: 400,
                      menubar: false,
                      directionality: "ltr",
                      language: "en",
                      language_url: "",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | styles | formatselect | " +
                        "bold italic underline strikethrough | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist outdent indent | " +
                        "link image media table blockquote | selectall",
                      style_formats: [
                        { title: "Heading 1", block: "h1" },
                        { title: "Heading 2", block: "h2" },
                        { title: "Heading 3", block: "h3" },
                        { title: "Heading 4", block: "h4" },
                        { title: "Heading 5", block: "h5" },
                        { title: "Heading 6", block: "h6" },
                        { title: "Paragraph", block: "p" },
                        { title: "Blockquote", block: "blockquote" },
                        { title: "Code", block: "code" },
                      ],
                      content_style:
                        "body { font-family: Arial, sans-serif; font-size: 14px; }",
                      branding: false,
                    }}
                    onEditorChange={(content, editor) => {
                      setDescription(content); // Update state with the new content
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <label className="form-label">
                    {t("Health Package Title one")}
                  </label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={titleone}
                    onChange={(e) => setTitleone(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">
                    {t("Health Package one Description")}
                  </label>
                  <Editor
                    key={`editor2-${activeLanguage}`}
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                    value={descriptionone} // Set the current content
                    init={{
                      height: 400,
                      menubar: false,
                      directionality: "ltr",
                      language: "en",
                      language_url: "",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | styles | formatselect | " +
                        "bold italic underline strikethrough | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist outdent indent | " +
                        "link image media table blockquote | selectall",
                      style_formats: [
                        { title: "Heading 1", block: "h1" },
                        { title: "Heading 2", block: "h2" },
                        { title: "Heading 3", block: "h3" },
                        { title: "Heading 4", block: "h4" },
                        { title: "Heading 5", block: "h5" },
                        { title: "Heading 6", block: "h6" },
                        { title: "Paragraph", block: "p" },
                        { title: "Blockquote", block: "blockquote" },
                        { title: "Code", block: "code" },
                      ],
                      content_style:
                        "body { font-family: Arial, sans-serif; font-size: 14px; }",
                      branding: false,
                    }}
                    onEditorChange={(content, editor) => {
                      setDescriptionone(content); // Update state with the new content
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <label className="form-label">
                    {t("Health Package Title Two")}
                  </label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={titletwo}
                    onChange={(e) => setTitletwo(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">
                    {t("Health Package Two Description")}
                  </label>
                  <Editor
                    key={`editor3-${activeLanguage}`}
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                    value={descriptiontwo} // Set the current content
                    init={{
                      height: 400,
                      menubar: false,
                      directionality: "ltr",
                      language: "en",
                      language_url: "",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | styles | formatselect | " +
                        "bold italic underline strikethrough | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist outdent indent | " +
                        "link image media table blockquote | selectall",
                      style_formats: [
                        { title: "Heading 1", block: "h1" },
                        { title: "Heading 2", block: "h2" },
                        { title: "Heading 3", block: "h3" },
                        { title: "Heading 4", block: "h4" },
                        { title: "Heading 5", block: "h5" },
                        { title: "Heading 6", block: "h6" },
                        { title: "Paragraph", block: "p" },
                        { title: "Blockquote", block: "blockquote" },
                        { title: "Code", block: "code" },
                      ],
                      content_style:
                        "body { font-family: Arial, sans-serif; font-size: 14px; }",
                      branding: false,
                    }}
                    onEditorChange={(content, editor) => {
                      setDescriptiontwo(content); // Update state with the new content
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <label className="form-label">{t("metatitle")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={metatitle}
                    onChange={(e) => setMetatitle(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("metadescription")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={metadescription}
                    onChange={(e) => setmetadescription(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("metakeywords")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={metakeyword}
                    onChange={(e) => setMetakeyword(e.target.value)}
                  />
                </div>
              </div>
              <div class="pt-4">
                {id ? (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleExcellanceUpdate}
                  >
                    {t("Update")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleExcellance}
                  >
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default PreventPackageForm;
