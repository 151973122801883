import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Form as BootstrapForm, Button, Col, Row } from "react-bootstrap";
import Select, { Option } from "react-select";
import DatePicker from "react-datepicker";
import ImageDropzone from "./Fields/ImageDropzone";
import FormServices from "../Services/FormServices";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { toast } from "react-toastify";
import { assetUrl } from "../Components/Common/Url";
import { useNavigate } from "react-router-dom";
import VideoDropzone from "./Fields/VideoDropZone";
import { url } from "../Components/Common/Url";
import axios from "axios";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";

const EnEditDoctorForm = ({ id }) => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [news, setNews] = useState([]);
  const { token } = useSelector((state) => state.user);
  const fetchDoctorData = async (id) => {
    try {
      const data = await FormServices.getDoctorById(id);

      setInitialValues({
        title: data?.en?.title || "",
        status: data?.en?.status || "",
        description: data?.en?.description || "",
        slug: data?.slug || "",
        speciality: data?.en?.speciality || "",
        featureImage: data?.en?.featureImage
          ? assetUrl + data.en.featureImage
          : "",
        featureVideo: data?.en?.featureVideo
          ? assetUrl + data.en.featureVideo
          : "",
        telephone: data?.en?.telephone || "",
        fax: data?.en?.fax || "",
        email: data?.en?.email || "",
        address: data?.en?.address || "",
        landmark: data?.en?.landmark || "",
        linkedBlog: data?.en?.linkedBlog || [],
        linkedNews: data?.en?.linkedNews || [],
        youtubeVideo: data?.en?.youtubeVideo,
        seo: {
          keywords: data?.en?.seo?.keywords || "",
          description: data?.en?.seo?.description || "",
          title: data?.en?.seo?.title || "",
        },

        doctorDetails: {
          externalLink: data?.en?.details?.externalLink || "",
          doctorProfileLink: data?.en?.details?.doctorProfileLink || "",
          bookNowForm: data?.en?.details?.bookNowForm || "",
          shortDescription: data?.en?.details?.shortDescription || "",
          generalInformation: data?.en?.details?.generalInformation || "",
          researchArticles: data?.en?.details?.researchArticle
            ? assetUrl + data.en?.details?.researchArticle
            : "",
          dateOfJoining: data?.en?.details?.dateOfJoining || "",
          timings: data?.en?.details?.timings || "",
          status: data?.en?.details?.status || "",
          clinics: data?.en?.details?.clinics || "",
          bestDoctor: data?.en?.details?.bestDoctor || false,
          logoImage: data?.en?.details?.logoImage
            ? assetUrl + data.en?.details?.logoImage
            : "",
        },
        testimonials: data?.en?.testimonials,
      });
    } catch (error) {
      console.log(error);
      toast.error("Error fetching doctor data");
    }
  };

  // Validation Schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    slug: Yup.string().required("Slug is required"),
  });

  const [specialities, setSpecialities] = useState([]);
  const [clinics, setClinics] = useState([]);

  const fetchSpecialities = async () => {
    try {
      const data = await FormServices.fetchSpecialities();
      setSpecialities(data);
    } catch (err) {
    } finally {
    }
  };

  const fetchBlogs = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/blog/get/`, {
        headers: {
          token: `${token}`,
        },
      });
      setBlogs(data.data);
    } catch (error) {
      toast.error("Failed to fetch blogs.");
    }
  };

  const fetchNews = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/news/get/`, {
        headers: {
          token: `${token}`,
        },
      });
      setNews(data);
    } catch (error) {
      toast.error("Failed to fetch news.");
    }
  };

  const fetchClinics = async () => {
    try {
      const data = await FormServices.fetchClinicsList();
      setClinics(data);
    } catch (err) {
    } finally {
    }
  };

  // const findSelectedBlog = () => {
  //   const blog = blogs.filter((blog) => blog._id === initialValues.linkedBlog);
  //   return {
  //     value: blog._id,
  //     label: blog.en_title,
  //   };
  // };

  const [initialValues, setInitialValues] = useState({
    title: "",
    status: "",
    description: "",
    slug: "",
    speciality: "",
    featureImage: null,
    featureVideo: null,
    telephone: "",
    fax: "",
    email: "",
    address: "",
    landmark: "",
    linkedBlog: [],
    linkedNews: [],
    youtubeVideo: [],
    seo: {
      keywords: "",
      description: "",
      title: "",
    },
    doctorDetails: {
      externalLink: "",
      doctorProfileLink: "",
      bookNowForm: "",
      shortDescription: "",
      generalInformation: [{ title: "", content: "" }],
      researchArticles: null,
      dateOfJoining: null,
      timings: [
        {
          clinic: "",
          timing: [
            {
              day: "",
              fromTimeFirstHalf: "",
              toTimeFirstHalf: "",
              fromTimeSecondHalf: "",
              toTimeSecondHalf: "",
              offDay: false,
            },
          ],
        },
      ],
      status: "active",
      clinics: "",
      bestDoctor: false,
      logoImage: null,
      testimonials: [
        {
          image: "",
          title: "",
          testimonial: "",
          address: "",
        },
      ],
    },
  });

  console.log("data", initialValues);
  const onSubmit = async (values) => {
    try {
      const response = await FormServices.updateDoctor(values, id);

      console.log(response);
      navigate(`/admin/doctors-list`);
      toast.success("Doctor has been updated successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchDoctorData(id);
    fetchSpecialities();
    fetchClinics();
    fetchBlogs();
    fetchNews();
  }, [id]);
  console.log(initialValues);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form id="enAddDoctorForm" onSubmit={handleSubmit}>
          <div className="card mb-3">
            <div class="card-body">
              <div className="mb-3">
                <label className="form-label">Title</label>
                <Field
                  className="form-control"
                  name="title"
                  onChange={(event) => {
                    setFieldValue("title", event.target.value);
                    setFieldValue(
                      "slug",
                      event.target.value.split(" ").join("-").toLowerCase()
                    );
                  }}
                />
                {errors.title && touched.title ? (
                  <div className="text-danger">{errors.title}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form-label">Slug</label>
                <Field className="form-control" name="slug" />
                {errors.slug && touched.slug ? (
                  <div className="text-danger">{errors.slug}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label className="form-label">Description</label>
                <Editor
                  apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                  initialValue={initialValues.description}
                  onEditorChange={(newContent) => {
                    setFieldValue("description", newContent);
                  }}
                  init={{
                    height: 400,
                    menubar: false,
                    directionality: "ltr",
                    language: "en",
                    language_url: "",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | styles | formatselect | " +
                      "bold italic underline strikethrough | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist outdent indent | " +
                      "link image media table blockquote | selectall",
                    style_formats: [
                      { title: "Heading 1", block: "h1" },
                      { title: "Heading 2", block: "h2" },
                      { title: "Heading 3", block: "h3" },
                      { title: "Heading 4", block: "h4" },
                      { title: "Heading 5", block: "h5" },
                      { title: "Heading 6", block: "h6" },
                      { title: "Paragraph", block: "p" },
                      { title: "Blockquote", block: "blockquote" },
                      { title: "Code", block: "code" },
                    ],
                    content_style:
                      "body { font-family: Arial, sans-serif; font-size: 14px; }",
                    branding: false,
                  }}
                />

                {errors.description && touched.description ? (
                  <div className="text-danger">{errors.description}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label className="form-label">Speciality</label>
                <Field as="select" name="speciality" className="form-select">
                  <option value="">Select a speciality</option>
                  {specialities.map((speciality) => (
                    <option key={speciality._id} value={speciality._id}>
                      {speciality.titleEn}
                    </option>
                  ))}
                </Field>
                {errors.speciality && touched.speciality ? (
                  <div className="text-danger">{errors.speciality}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form-label">Feature Image</label>
                <ImageDropzone
                  onFilesAdded={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setFieldValue("featureImage", acceptedFiles[0]);
                    }
                  }}
                />

                {errors.featureImage && touched.featureImage ? (
                  <div className="text-danger">{errors.featureImage}</div>
                ) : null}

                {initialValues?.featureImage && (
                  <div className="demo-inline-spacing">
                    <img
                      src={initialValues?.featureImage}
                      alt="Uploaded"
                      style={{ maxWidth: "100px", height: "auto" }}
                    />
                  </div>
                )}
                <div className="mb-3">
                  <label className="form-label">Feature Video</label>
                  <VideoDropzone
                    onFilesAdded={(acceptedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setFieldValue("featureVideo", acceptedFiles[0]);
                      }
                    }}
                  />
                  {errors.featureVideo && touched.featureVideo ? (
                    <div className="text-danger">{errors.featureVideo}</div>
                  ) : null}
                </div>
                {initialValues?.featureVideo && (
                  <div className="demo-inline-spacing">
                    <video
                      controls
                      src={initialValues.featureVideo}
                      alt="Uploaded"
                      style={{ Width: "40%", height: "100%" }}
                    />
                  </div>
                )}

                <div className="mb-3">
                  <h5 className="mt-1"> Youtube Videos</h5>

                  <FieldArray name="youtubeVideo">
                    {({ insert, remove, push }) => (
                      <div className="general-info-container">
                        {values.youtubeVideo.length > 0 &&
                          values.youtubeVideo.map((info, index) => (
                            <div key={index}>
                              <Row>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`youtubeVideo.${index}.title`}
                                    className="mb-3"
                                  >
                                    <Field
                                      placeholder="Enter video title"
                                      name={`youtubeVideo.${index}.title`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                  <BootstrapForm.Group
                                    controlId={`youtubeVideo.${index}.video`}
                                    className="mb-3"
                                  >
                                    <Field
                                      placeholder="Enter video link"
                                      name={`youtubeVideo.${index}.video`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>

                                <Col>
                                  <span
                                    class="badge badge-center rounded-pill bg-danger"
                                    onClick={() => remove(index)}
                                  >
                                    {" "}
                                    <i class="ti ti-x ti-xs"></i>
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        <div class="mb-2">
                          <Button
                            size="sm"
                            className="btn btn-primary waves-effect waves-light"
                            variant="primary"
                            onClick={() => push({ video: "" })}
                          >
                            <i className="ti ti-plus ti-xs me-2"></i>
                            <span class="align-middle">Add</span>
                          </Button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Status</label>
                <Field className="form-control" as="select" name="status">
                  <option value="">Select Status</option>
                  <option value="published">Published</option>
                  <option value="pending">Pending</option>
                  <option value="draft">Draft</option>
                </Field>
                {errors.status && touched.status ? (
                  <div className="text-danger">{errors.status}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <h5 className="card-header">SEO</h5>

            <div class="card-body">
              <div className="mb-3">
                <label className="form-label">Keywords</label>
                <Field className="form-control" name="seo.keywords" />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <Field className="form-control" name="seo.description" />
              </div>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <Field className="form-control" name="seo.title" />
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <h5 className="card-header">Doctor Details</h5>

            <div class="card-body">
              <div className="mb-3">
                <label className="form-label">Telephone</label>
                <Field className="form-control" name="telephone" />
              </div>
              <div className="mb-3">
                <label className="form-label">Fax</label>
                <Field className="form-control" name="fax" />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <Field className="form-control" name="email" />
              </div>
              <div className="mb-3">
                <label className="form-label">Address</label>
                <Field className="form-control" name="address" />
              </div>
              <div className="mb-3">
                <label className="form-label">Landmark</label>
                <Field className="form-control" name="landmark" />
              </div>
              <div className="mb-3">
                <label className="form-label">External Link</label>
                <Field
                  className="form-control"
                  name="doctorDetails.externalLink"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Doctor Profile Link</label>
                <Field
                  className="form-control"
                  name="doctorDetails.doctorProfileLink"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Book Now Form</label>
                <Field
                  className="form-control"
                  name="doctorDetails.bookNowForm"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Short Description</label>
                <Field
                  className="form-control"
                  name="doctorDetails.shortDescription"
                  as="textarea"
                />
              </div>

              <div className="mb-3">
                <h5 className="mt-1"> General Information</h5>

                <FieldArray name="doctorDetails.generalInformation">
                  {({ insert, remove, push }) => (
                    <div className="general-info-container">
                      <Row>
                        <Col>
                          <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>Title</BootstrapForm.Label>
                          </BootstrapForm.Group>
                        </Col>
                        <Col>
                          <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>
                              Description
                            </BootstrapForm.Label>
                          </BootstrapForm.Group>
                        </Col>
                        <Col></Col>
                      </Row>

                      {values.doctorDetails.generalInformation.length > 0 &&
                        values.doctorDetails.generalInformation.map(
                          (info, index) => (
                            <div key={index}>
                              <Row>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`doctorDetails.generalInformation.${index}.title`}
                                    className="mb-3"
                                  >
                                    <Field
                                      placeholder="Enter Title"
                                      name={`doctorDetails.generalInformation.${index}.title`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`doctorDetails.generalInformation.${index}.content`}
                                    className="mb-3"
                                  >
                                    <Editor
                                      apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                                      initialValue={
                                        initialValues?.doctorDetails
                                          ?.generalInformation?.[index]
                                          ?.content || ""
                                      }
                                      onEditorChange={(newContent) => {
                                        setFieldValue(
                                          `doctorDetails.generalInformation.${index}.content`,
                                          newContent
                                        );
                                      }}
                                      init={{
                                        height: 400,
                                        menubar: false,
                                        directionality: "ltr",
                                        language: "en",
                                        language_url: "",
                                        plugins: [
                                          "advlist autolink lists link image charmap print preview anchor",
                                          "searchreplace visualblocks code fullscreen",
                                          "insertdatetime media table paste code help wordcount",
                                        ],
                                        toolbar:
                                          "undo redo | styles | formatselect | " +
                                          "bold italic underline strikethrough | " +
                                          "alignleft aligncenter alignright alignjustify | " +
                                          "bullist numlist outdent indent | " +
                                          "link image media table blockquote | selectall",
                                        style_formats: [
                                          { title: "Heading 1", block: "h1" },
                                          { title: "Heading 2", block: "h2" },
                                          { title: "Heading 3", block: "h3" },
                                          { title: "Heading 4", block: "h4" },
                                          { title: "Heading 5", block: "h5" },
                                          { title: "Heading 6", block: "h6" },
                                          { title: "Paragraph", block: "p" },
                                          {
                                            title: "Blockquote",
                                            block: "blockquote",
                                          },
                                          { title: "Code", block: "code" },
                                        ],
                                        content_style:
                                          "body { font-family: Arial, sans-serif; font-size: 14px; }",
                                        branding: false,
                                      }}
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <span
                                    class="badge badge-center rounded-pill bg-danger"
                                    onClick={() => remove(index)}
                                  >
                                    {" "}
                                    <i class="ti ti-x ti-xs"></i>
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          )
                        )}
                      <div class="mb-2">
                        <Button
                          size="sm"
                          className="btn btn-primary waves-effect waves-light"
                          variant="primary"
                          onClick={() => push({ title: "", content: "" })}
                        >
                          <i className="ti ti-plus ti-xs me-2"></i>
                          <span class="align-middle">Add</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className="mb-3">
                <label className="form-label">Research Articles</label>

                <ImageDropzone
                  onFilesAdded={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setFieldValue(
                        "doctorDetails.researchArticles",
                        acceptedFiles[0]
                      );
                    }
                  }}
                />

                {initialValues?.doctorDetails?.researchArticles && (
                  <div className="demo-inline-spacing">
                    <img
                      src={initialValues?.doctorDetails?.researchArticles}
                      alt="Uploaded"
                      style={{ maxWidth: "100px", height: "auto" }}
                    />
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">Date of Joining</label>
                <br />

                <Field
                  name="doctorDetails.dateOfJoining"
                  component={({ field, form }) => {
                    // Convert the string date to a Date object if necessary
                    const dateValue = field.value
                      ? new Date(field.value)
                      : null;

                    return (
                      <DatePicker
                        selected={dateValue}
                        onChange={(date) =>
                          form.setFieldValue(field.name, date)
                        }
                        className="form-control"
                        dateFormat="yyyy-MM-dd" // Adjust format as needed
                      />
                    );
                  }}
                />
              </div>

              <div className="mb-3">
                <h5 className="mt-1"> Timings</h5>

                <FieldArray name="doctorDetails.timings">
                  {({ insert, remove, push }) => (
                    <div className="clinic-container">
                      {values.doctorDetails.timings.length > 0 &&
                        values.doctorDetails.timings.map((timing, index) => (
                          <>
                            <div key={index}>
                              <div className="mb-3">
                                <label className="form-label">Clinic</label>
                                <Field
                                  as="select"
                                  name={`doctorDetails.timings.${index}.clinic`}
                                  className="form-select"
                                >
                                  <option value="">Select a clinic</option>
                                  {clinics.map((clinic) => (
                                    <option key={clinic._id} value={clinic._id}>
                                      {clinic.title}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <div className="mb-3">
                                <FieldArray
                                  name={`doctorDetails.timings.${index}.timing`}
                                >
                                  {({ insert, remove, push }) => (
                                    <div className="clinic-timings">
                                      <Row>
                                        <Col>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              Day
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              From Time (First Half)
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              To Time (First Half)
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              From Time (Second Half)
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              To Time (Second Half)
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                        <Col sm={2}>
                                          <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>
                                              Off Day
                                            </BootstrapForm.Label>
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                      {timing.timing.length > 0 &&
                                        timing.timing.map((time, idx) => (
                                          <div key={idx}>
                                            <Row>
                                              <Col>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.day`}
                                                  className="mb-3"
                                                >
                                                  <Field
                                                    name={`doctorDetails.timings.${index}.timing.${idx}.day`}
                                                    as="select"
                                                    className="form-control"
                                                  >
                                                    <option value="">
                                                      Select Day
                                                    </option>
                                                    <option value="Sunday">
                                                      Sunday
                                                    </option>
                                                    <option value="Monday">
                                                      Monday
                                                    </option>
                                                    <option value="Tuesday">
                                                      Tuesday
                                                    </option>
                                                    <option value="Wednesday">
                                                      Wednesday
                                                    </option>
                                                    <option value="Thursday">
                                                      Thursday
                                                    </option>
                                                    <option value="Friday">
                                                      Friday
                                                    </option>
                                                    <option value="Saturday">
                                                      Saturday
                                                    </option>
                                                  </Field>
                                                </BootstrapForm.Group>
                                              </Col>
                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.fromTimeFirstHalf`}
                                                  className="mb-3"
                                                >
                                                  <TimePicker
                                                    format="h:m a"
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `doctorDetails.timings.${index}.timing.${idx}.fromTimeFirstHalf`,
                                                        value
                                                      )
                                                    }
                                                    value={
                                                      values.doctorDetails
                                                        .timings[index].timing[
                                                        idx
                                                      ].fromTimeFirstHalf
                                                    }
                                                  />
                                                </BootstrapForm.Group>
                                              </Col>

                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.toTimeFirstHalf`}
                                                  className="mb-3"
                                                >
                                                  <TimePicker
                                                    format="h:m a"
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `doctorDetails.timings.${index}.timing.${idx}.toTimeFirstHalf`,
                                                        value
                                                      )
                                                    }
                                                    value={
                                                      values.doctorDetails
                                                        .timings[index].timing[
                                                        idx
                                                      ].toTimeFirstHalf
                                                    }
                                                  />
                                                </BootstrapForm.Group>
                                              </Col>
                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.fromTimeSecondHalf`}
                                                  className="mb-3"
                                                >
                                                  <TimePicker
                                                    format="h:m a"
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `doctorDetails.timings.${index}.timing.${idx}.fromTimeSecondHalf`,
                                                        value
                                                      )
                                                    }
                                                    value={
                                                      values.doctorDetails
                                                        .timings[index].timing[
                                                        idx
                                                      ].fromTimeSecondHalf
                                                    }
                                                  />
                                                </BootstrapForm.Group>
                                              </Col>
                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.toTimeSecondHalf`}
                                                  className="mb-3"
                                                >
                                                  <TimePicker
                                                    format="h:m a"
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `doctorDetails.timings.${index}.timing.${idx}.toTimeSecondHalf`,
                                                        value
                                                      )
                                                    }
                                                    value={
                                                      values.doctorDetails
                                                        .timings[index].timing[
                                                        idx
                                                      ].toTimeSecondHalf
                                                    }
                                                  />
                                                </BootstrapForm.Group>
                                              </Col>

                                              <Col sm={2}>
                                                <BootstrapForm.Group
                                                  controlId={`doctorDetails.timings.${index}.timing.${idx}.offDay`}
                                                  className="mb-3"
                                                >
                                                  <div className="col-12 d-flex align-items-center gap-2">
                                                    <div className="form-check">
                                                      <Field
                                                        name={`doctorDetails.timings.${index}.timing.${idx}.offDay`}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                      />
                                                      <label className="form-check-label">
                                                        {" "}
                                                        Yes{" "}
                                                      </label>
                                                    </div>
                                                    <span
                                                      class="badge badge-center rounded-pill bg-danger"
                                                      onClick={() =>
                                                        remove(idx)
                                                      }
                                                    >
                                                      {" "}
                                                      <i class="ti ti-x ti-xs"></i>
                                                    </span>
                                                  </div>
                                                </BootstrapForm.Group>
                                              </Col>
                                            </Row>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                              }}
                                            >
                                              OR
                                            </div>
                                            <div className="mb-3">
                                              <label className="form-label">
                                                Custom data for day
                                              </label>
                                              <Field
                                                placeholder="Enter custom data for day"
                                                className="form-control"
                                                name={`doctorDetails.timings.${index}.timing.${idx}.day`}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      <Button
                                        size="sm"
                                        className="btn btn-primary waves-effect waves-light mb-2"
                                        variant="primary"
                                        onClick={() =>
                                          push({
                                            day: "",
                                            fromTimeFirstHalf: "",
                                            toTimeFirstHalf: "",
                                            fromTimeSecondHalf: "",
                                            toTimeSecondHalf: "",
                                            offDay: false,
                                          })
                                        }
                                      >
                                        Add Timing
                                      </Button>
                                    </div>
                                  )}
                                </FieldArray>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  className=" btn btn-danger waves-effect waves-light"
                                  type="button"
                                  onClick={() => remove(index)}
                                >
                                  Remove Clinic
                                </Button>
                              </div>
                            </div>
                            <hr className="my-6 mx-n4" />
                          </>
                        ))}

                      <Button
                        size="sm"
                        className="btn btn-primary waves-effect waves-light"
                        variant="primary"
                        onClick={() =>
                          push({
                            clinic: "",
                            timing: [
                              {
                                day: "",
                                fromTimeFirstHalf: "",
                                toTimeFirstHalf: "",
                                fromTimeSecondHalf: "",
                                toTimeSecondHalf: "",
                                offDay: false,
                              },
                            ],
                          })
                        }
                      >
                        <i className="ti ti-plus ti-xs me-2"></i>
                        <span class="align-middle">Add Clinic</span>
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className="mb-3">
                <label className="form-label">Availibilty Status</label>
                <Field
                  className="form-control"
                  as="select"
                  name="doctorDetails.status"
                >
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Field>
              </div>
              <div className="mb-3">
                <label className="form-label">Clinics</label>
                <Field
                  as="select"
                  name="doctorDetails.clinics"
                  className="form-select"
                >
                  <option value="">Select clinics</option>
                  {clinics.map((clinic) => (
                    <option
                      key={clinic._id}
                      value={clinic._id}
                      selected={initialValues.doctorDetails.clinics?.includes(
                        clinic._id
                      )}
                    >
                      {clinic.title}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="mb-3">
                {" "}
                <label className="form-label">Best Doctor</label>
                <div className="form-check">
                  <Field
                    name="doctorDetails.bestDoctor"
                    type="checkbox"
                    className="form-check-input"
                  />
                  <label className="form-check-label"> Yes </label>
                </div>
              </div>
              <BootstrapForm.Group controlId="linkedBlog" className="mb-3">
                <BootstrapForm.Label>Add Blogs</BootstrapForm.Label>

                <Select
                  isMulti
                  name="linkedBlog"
                  options={blogs?.map((blog) => ({
                    value: blog?._id,
                    label: blog?.en_title,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={values.linkedBlog
                    .map((blog) =>
                      blogs.find((_blog) => _blog._id === blog.value)
                        ? {
                            value: blog.value,
                            label: blog.label,
                          }
                        : null
                    )
                    .filter(Boolean)}
                  onChange={(selectedOptions) =>
                    setFieldValue(
                      "linkedBlog",
                      selectedOptions.map((option) => option)
                    )
                  }
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="linkedNews" className="mb-3">
                <BootstrapForm.Label>Add News</BootstrapForm.Label>
                <Select
                  isMulti
                  name="linkedNews"
                  options={news?.map((news) => ({
                    value: news?._id,
                    label: news?.en_title,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={values.linkedNews
                    .map((nws) =>
                      news.find((_news) => _news._id === nws.value)
                        ? {
                            value: nws.value,
                            label: nws.label,
                          }
                        : null
                    )
                    .filter(Boolean)}
                  onChange={(selectedOptions) =>
                    setFieldValue(
                      "linkedNews",
                      selectedOptions.map((option) => option)
                    )
                  }
                />
              </BootstrapForm.Group>
              {/* <div className="mb-3">
                <label className="form-label">Blogs</label>
                <Field as="select" name="linkedBlog" className="form-select">
                  <option value="">Select Blog</option>
                  {blogs &&
                    blogs.map((blog) => (
                      <option
                        key={blog._id}
                        value={blog._id}
                        selected={blog._id === initialValues.linkedBlog}
                      >
                        {blog.en_title}
                      </option>
                    ))}
                </Field>
              </div> */}
              <div className="mb-3">
                <label className="form-label">Logo Image</label>
                <ImageDropzone
                  onFilesAdded={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setFieldValue(
                        "doctorDetails.logoImage",
                        acceptedFiles[0]
                      );
                    }
                  }}
                />

                {initialValues?.doctorDetails?.logoImage && (
                  <div className="demo-inline-spacing">
                    <img
                      src={initialValues?.doctorDetails?.logoImage}
                      alt="Uploaded"
                      style={{ maxWidth: "100px", height: "auto" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div class="card mb-6 mb-3">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="mb-0 card-title">Testimonials</h5>
            </div>
            <div class="card-body">
              <FieldArray name="testimonials">
                {({ insert, remove, push }) => (
                  <div className="testimonial-container">
                    <Row>
                      <Col>
                        <BootstrapForm.Group className="mb-3">
                          <BootstrapForm.Label>Image</BootstrapForm.Label>
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group className="mb-3">
                          <BootstrapForm.Label>Title</BootstrapForm.Label>
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group className="mb-3">
                          <BootstrapForm.Label>Testimonial</BootstrapForm.Label>
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group className="mb-3">
                          <BootstrapForm.Label>Address</BootstrapForm.Label>
                        </BootstrapForm.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                    {values?.testimonials?.map((testimonial, index) => (
                      <div key={index}>
                        <Row>
                          <Col>
                            <BootstrapForm.Group
                              controlId={`testimonials.${index}.image`}
                              className="mb-3"
                            >
                              <div className="drop-testimonial">
                                <ImageDropzone
                                  onFilesAdded={(acceptedFiles) => {
                                    if (acceptedFiles.length > 0) {
                                      console.log(acceptedFiles[0]);
                                      setFieldValue(
                                        `testimonials.${index}.image`,
                                        acceptedFiles[0]
                                      );
                                    }
                                  }}
                                />

                                {testimonial[index]?.image !== "" && (
                                  <>
                                    <div
                                      className=""
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <img
                                        src={`${url}${initialValues?.testimonials[index]?.image}`}
                                        width={"100%"}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </BootstrapForm.Group>
                          </Col>
                          <Col>
                            <BootstrapForm.Group
                              controlId={`testimonials.${index}.title`}
                              className="mb-3"
                            >
                              <Field
                                placeholder="Enter Title"
                                name={`testimonials.${index}.title`}
                                className="form-control"
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col>
                            <BootstrapForm.Group
                              controlId={`testimonials.${index}.testimonial`}
                              className="mb-3"
                            >
                              <Field
                                placeholder="Enter Testimonial"
                                name={`testimonials.${index}.testimonial`}
                                className="form-control"
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col>
                            <BootstrapForm.Group
                              controlId={`testimonials.${index}.address`}
                              className="mb-3"
                            >
                              <Field
                                placeholder="Enter Address"
                                name={`testimonials.${index}.address`}
                                className="form-control"
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col>
                            <span
                              class="badge badge-center rounded-pill bg-danger"
                              onClick={() => remove(index)}
                            >
                              {" "}
                              <i class="ti ti-x ti-xs"></i>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <div class="mb-2">
                      <Button
                        size="sm"
                        className="btn btn-primary waves-effect waves-light"
                        variant="primary"
                        onClick={() =>
                          push({
                            image: "",
                            title: "",
                            testimonial: "",
                            address: "",
                          })
                        }
                      >
                        <i className="ti ti-plus ti-xs me-2"></i>
                        <span class="align-middle">Add</span>
                      </Button>
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <Button
                className="btn btn-success waves-effect waves-light"
                variant="success"
                type="submit"
              >
                Update
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EnEditDoctorForm;
