import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import FabIcon from "../FabIcon";
import AboutImageUpload from "../AboutusImageUpload";

const IndexPageForm = () => {
  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [description, setDescription] = useState("");
  const [aboutdes, setAboutDesc] = useState("");

  const [slider, setSlider] = useState(null);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  const [singleData, setSingleData] = useState({});
  const navigate = useNavigate();

  const handleDrop = useCallback((acceptedFiles) => {
    // Assuming you only want to handle one file at a time
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      // Do something with the valid image file
      console.log("Valid image file:", file);
      setSlider(file);
    } else {
      // Handle invalid file type
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    console.log(singleData);
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  useEffect(() => {
    const get_excellanace_data = async () => {
      const { data } = await axios.get(`${url}/api/v1/teleconsultant/get/`, {
        headers: {
          token: `${token}`,
        },
      });
      console.log(data);
      if (activeLanguage === "ar") {
        setDescription(data.data.ar_description);
        setAboutDesc(data.data.ar_personal_description);
        setSlider(data.data.ar_image);
        setImage(data.data.ar_personal_image);
      } else {
        setDescription(data.data.en_description);
        setAboutDesc(data.data.en_personal_description);
        setSlider(data.data.en_image);
        setImage(data.data.en_personal_image);
      }
    };
    get_excellanace_data();
  }, [activeLanguage]);

  const handleExcellanceUpdate = async () => {
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("description", description);
    formData.append("slider", slider);
    formData.append("image", image);
    formData.append("aboutdes", aboutdes);

    try {
      const { data } = await axios.put(
        `${url}/api/v1/teleconsultant/update/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <div>
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">{t("Image")}</label>
                      <br />

                      <div {...getRootProps()} style={dropzoneStyles}>
                        <input {...getInputProps()} />
                        <p>
                          {t(
                            "Drag and drop an image file here, or click to select one"
                          )}
                        </p>
                      </div>
                      {slider && slider.path}

                      {slider && (
                        <img
                          src={`${Image}/${slider}`}
                          width={"250px"}
                          className="mt-4"
                        />
                      )}
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">{t("Description")}</label>
                      <Editor
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                        key={`editor1-${activeLanguage}`}
                        init={{
                          height: 400,
                          menubar: false,
                          directionality: "ltr",
                          language: "en",
                          language_url: "",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | styles | formatselect | " +
                            "bold italic underline strikethrough | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist outdent indent | " +
                            "link image media table blockquote | selectall",
                          style_formats: [
                            { title: "Heading 1", block: "h1" },
                            { title: "Heading 2", block: "h2" },
                            { title: "Heading 3", block: "h3" },
                            { title: "Heading 4", block: "h4" },
                            { title: "Heading 5", block: "h5" },
                            { title: "Heading 6", block: "h6" },
                            { title: "Paragraph", block: "p" },
                            { title: "Blockquote", block: "blockquote" },
                            { title: "Code", block: "code" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }",
                          branding: false,
                        }}
                        value={description}
                        onEditorChange={(newValue) => setDescription(newValue)} // Update state with editor content
                      />
                    </div>

                    <div className="col-md-12">
                      <h4>{t("Personal Healthcare")}</h4>
                      <label className="form-label">{t("Description")}</label>
                      <Editor
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                        key={`editor2-${activeLanguage}`}
                        init={{
                          height: 400,
                          menubar: false,
                          directionality: "ltr",
                          language: "en",
                          language_url: "",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | styles | formatselect | " +
                            "bold italic underline strikethrough | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist outdent indent | " +
                            "link image media table blockquote | selectall",
                          style_formats: [
                            { title: "Heading 1", block: "h1" },
                            { title: "Heading 2", block: "h2" },
                            { title: "Heading 3", block: "h3" },
                            { title: "Heading 4", block: "h4" },
                            { title: "Heading 5", block: "h5" },
                            { title: "Heading 6", block: "h6" },
                            { title: "Paragraph", block: "p" },
                            { title: "Blockquote", block: "blockquote" },
                            { title: "Code", block: "code" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }",
                          branding: false,
                        }}
                        value={aboutdes}
                        onEditorChange={(newValue) => setAboutDesc(newValue)} // Update state with editor content
                      />

                      <hr />
                      <label className="form-label">{t("Image")}</label>
                      <br />

                      <AboutImageUpload image={image} setImage={setImage} />
                      {image && image.path}
                      {image && (
                        <img
                          src={`${Image}/${image}`}
                          width={"250px"}
                          className="mt-4"
                        />
                      )}
                    </div>
                  </div>
                  <div class="pt-4">
                    <button
                      type="submit"
                      class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={handleExcellanceUpdate}
                    >
                      {t("Update")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default IndexPageForm;
