import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormServices from "../../Services/FormServices";
import { BASE_URL } from "../../Components/Common/Url";
import axios from "axios";

const EditUser = () => {
  const { id } = useParams();
    const [user, setUser] = useState({
      name: "",
      email: "",
      password: "",
      canEdit: false,
      canAdd: false,
      canDelete: false,
    });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchUserById = async () => {
      setLoading(true);
      try {
        const data = await FormServices.fetchUsers();
        const foundUser = data.users.find((u) => u._id === id);
        if (!foundUser) {
          throw new Error("User not found");
        }
        setUser(foundUser);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserById();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser({
      ...user,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}api/v1/user/update/${id}`, user, {
        withCredentials: true,
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || "Update failed");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!user) return <p>No user found</p>;

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Edit User</h2>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit} className="card p-4 shadow">
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={user.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={user.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="canEdit"
            checked={user.canEdit}
            onChange={handleChange}
          />
          <label className="form-check-label">Can Edit</label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="canAdd"
            checked={user.canAdd}
            onChange={handleChange}
          />
          <label className="form-check-label">Can Add</label>
        </div>
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            name="canDelete"
            checked={user.canDelete}
            onChange={handleChange}
          />
          <label className="form-check-label">Can Delete</label>
        </div>
        <button type="submit" className="btn btn-success">
          Update User
        </button>
      </form>
    </div>
  );
};

export default EditUser;
