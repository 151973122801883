import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import "../../i18n";

import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { url } from "../../Components/Common/Url";
import { Link, useParams } from "react-router-dom";

const FaqForm = () => {
  const { token } = useSelector((state) => state.user);
  const params = useParams();
  const { id } = params;

  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question && !answer) {
      return toast(`Please Enter Question and Answer`);
    }
    const response = await fetch(`${url}/api/v1/rpctestfaq/add`, {
      method: "POST",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lan: activeLanguage,
        answer,
        question,
      }),
    });
    const result = await response.json();

    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const get_signle_faq = async () => {
    const response = await fetch(`${url}/api/v1/rpctestfaq/get/${id}`, {
      method: "GET",
      headers: {
        token: `${token}`,
      },
    });
    const result = await response.json();
    console.log("single data", result);
    if (activeLanguage == "ar") {
      setQuestion(result.data.ar_question);
      setAnswer(result.data.ar_answer);
    } else {
      setQuestion(result.data.en_question);
      setAnswer(result.data.en_answer);
    }
  };

  useEffect(() => {
    if (id) {
      get_signle_faq();
    } else {
      setQuestion("");
      setAnswer("");
    }
  }, [id, activeLanguage]);

  const handleupdate = async (e) => {
    e.preventDefault();
    console.log(answer);
    console.log(question);
    try {
      const response = await fetch(
        `${url}/api/v1/rpctestfaq/update/faq/${id}`,
        {
          method: "PUT",
          headers: {
            token: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lan: activeLanguage,
            answer,
            question,
          }),
        }
      );

      const result = await response.json();
      console.log(result);
      toast.success(result.message);
      if (result.status == true) {
        setInterval(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error);
    }
  };

  return (
    <div className="col-md-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <form onSubmit={id ? handleupdate : handleSubmit}>
              <div className="row g-3">
                <div className="col-lg-12">
                  <label className="form-label">{t("Question")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Answer")}</label>
                  <Editor
                    key={`editor1-${activeLanguage}`}
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                    value={answer} // The content value
                    init={{
                      height: 400,
                      menubar: false,
                      directionality: "ltr",
                      language: "en",
                      language_url: "",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | styles | formatselect | " +
                        "bold italic underline strikethrough | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist outdent indent | " +
                        "link image media table blockquote | selectall",
                      style_formats: [
                        { title: "Heading 1", block: "h1" },
                        { title: "Heading 2", block: "h2" },
                        { title: "Heading 3", block: "h3" },
                        { title: "Heading 4", block: "h4" },
                        { title: "Heading 5", block: "h5" },
                        { title: "Heading 6", block: "h6" },
                        { title: "Paragraph", block: "p" },
                        { title: "Blockquote", block: "blockquote" },
                        { title: "Code", block: "code" },
                      ],
                      content_style:
                        "body { font-family: Arial, sans-serif; font-size: 14px; }",
                      branding: false,
                    }}
                    onEditorChange={(content, editor) => {
                      setAnswer(content); // Update the state with new content
                    }}
                  />
                </div>
              </div>
              <div class="pt-4">
                <button
                  type="submit"
                  class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqForm;
