import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import PdfUpload from "./../PdfUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const TestimonialForm = () => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [sdescription, setSdescription] = useState("");

  const [description, setDescription] = useState("");

  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const navigate = useNavigate();

  const handleDrop = useCallback((acceptedFiles) => {
    // Assuming you only want to handle one file at a time
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      // Do something with the valid image file
      console.log("Valid image file:", file);
      setImage(file);
    } else {
      // Handle invalid file type
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleStep = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }

    //alert(cat)
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("description", description);
    formData.append("sdescription", sdescription);
    formData.append("seoTitleEn", seoTitleEn);
    formData.append("seoKeywordsEn", seoKeywordsEn);
    formData.append("seoDescEn", seoDescEn);
    formData.append("seoTitleAr", seoTitleAr);
    formData.append("seoKeywordsAr", seoKeywordsAr);
    formData.append("seoDescAr", seoDescAr);

    console.log(formData);

    try {
      const { data } = await axios.post(
        `${url}/api/v1/testimonial/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const get_step_data = async () => {
        const { data } = await axios.get(
          `${url}/api/v1/testimonial/get/${id}`,
          {
            headers: {
              token: `${token}`,
            },
          }
        );
        console.log(data);
        if (activeLanguage === "ar") {
          setDescription(data.data.ar_description);
          setTitle(data.data.ar_title);
          setImage(data.data.ar_image);
          setSdescription(data.data.ar_short_description);
          setSeoKeywordsAr(data.data.seoKeywordsAr);
          setSeoDescAr(data.data.seoDescAr);
          setSeoTitleAr(data.data.seoTitleAr);
        } else {
          setDescription(data.data.en_description);
          setTitle(data.data.en_title);
          setImage(data.data.en_image);
          setSdescription(data.data.en_short_description);
          setSeoKeywordsEn(data.data.seoKeywordsEn);
          setSeoDescEn(data.data.seoDescEn);
          setSeoTitleEn(data.data.seoTitleEn);
        }
      };
      get_step_data();
    } else {
      setDescription("");
      setTitle("");
      setImage("");
      setSdescription("");
    }
  }, [id, activeLanguage]);

  const handleStepUpdate = async () => {
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("description", description);
    formData.append("sdescription", sdescription);
    formData.append("seoTitleEn", seoTitleEn);
    formData.append("seoKeywordsEn", seoKeywordsEn);
    formData.append("seoDescEn", seoDescEn);
    formData.append("seoTitleAr", seoTitleAr);
    formData.append("seoKeywordsAr", seoKeywordsAr);
    formData.append("seoDescAr", seoDescAr);

    try {
      const { data } = await axios.put(
        `${url}/api/v1/testimonial/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="col-md-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div>
              <div className="row g-3">
                <div className="col-md-12">
                  <label className="form-label">{t("Title")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Short Description")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={sdescription}
                    onChange={(e) => setSdescription(e.target.value)}
                  />
                </div>

                <div className="col-md-12">
                  <label className="form-label">{t("Image")}</label>
                  <br />
                  {image && <img src={`${Image}/${image}`} width={"50px"} />}

                  <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>
                      {t(
                        "Drag and drop an image file here, or click to select one"
                      )}
                    </p>
                  </div>
                  {image && image.path}
                </div>

                <div className="col-md-12">
                  <label className="form-label">{t("Description")}</label>
                  <br />
                  <Editor
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                    key={`editor1-${activeLanguage}`}
                    value={description}
                    init={{
                      height: 400,
                      menubar: false,
                      directionality: "ltr",
                      language: "en",
                      language_url: "",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | styles | formatselect | " +
                        "bold italic underline strikethrough | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist outdent indent | " +
                        "link image media table blockquote | selectall",
                      style_formats: [
                        { title: "Heading 1", block: "h1" },
                        { title: "Heading 2", block: "h2" },
                        { title: "Heading 3", block: "h3" },
                        { title: "Heading 4", block: "h4" },
                        { title: "Heading 5", block: "h5" },
                        { title: "Heading 6", block: "h6" },
                        { title: "Paragraph", block: "p" },
                        { title: "Blockquote", block: "blockquote" },
                        { title: "Code", block: "code" },
                      ],
                      content_style:
                        "body { font-family: Arial, sans-serif; font-size: 14px; }",
                      branding: false,
                    }}
                  />
                </div>
              </div>
              {activeLanguage === "en" ? (
                <>
                  <div className="card mb-3 mt-3">
                    <h5 className="card-header">Corporate Wellness SEO (En)</h5>

                    <div class="card-body">
                      <div className="mb-3">
                        <label className="form-label">Keywords</label>
                        <input
                          value={seoKeywordsEn}
                          onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input
                          value={seoDescEn}
                          onChange={(ev) => setSeoDescEn(ev.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                          value={seoTitleEn}
                          onChange={(ev) => setSeoTitleEn(ev.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="card mb-3">
                    <h5 className="card-header">Corporate Wellness SEO (Ar)</h5>

                    <div class="card-body">
                      <div className="mb-3">
                        <label className="form-label">Keywords</label>
                        <input
                          value={seoKeywordsAr}
                          onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                          className="form-control"
                          dir="rtl"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input
                          value={seoDescAr}
                          onChange={(ev) => setSeoDescAr(ev.target.value)}
                          className="form-control"
                          dir="rtl"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                          value={seoTitleAr}
                          onChange={(ev) => setSeoTitleAr(ev.target.value)}
                          className="form-control"
                          dir="rtl"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div class="pt-4">
                {id ? (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleStepUpdate}
                  >
                    {t("Update")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleStep}
                  >
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default TestimonialForm;
