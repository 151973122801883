import React, { useEffect, useState } from "react";
import { url } from "../Common/Url";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

export default function ChairMan() {
  const [textEditor, setTextEditor] = useState("");
  const [title, setTitle] = useState("");
  const [hidden, setHidden] = useState(true);
  const [lan, setLan] = useState("en");
  const [data, setData] = useState({});
  const { token } = useSelector((state) => state.user);

  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const get_aster_clinic = async () => {
    const response = await fetch(`${url}/api/v1/user/chairmain`, {
      method: "GET",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    // console.log(result)
    setData(result.data);
  };

  useEffect(() => {
    get_aster_clinic();
  }, []);

  const handleLanguge = (e) => {
    if (e.target.value == "Ar") {
      setLan("Ar");
      setTitle(data.ar_title);
      setTextEditor(data.ar_description);
      setHidden(false);

      setSeoKeywordsAr(data.seoKeywordsAr);
      setSeoDescAr(data.seoDescAr);
      setSeoTitleAr(data.seoTitleAr);
    } else {
      setLan("en");
      setTitle(data.en_title);
      setTextEditor(data.en_description);
      setHidden(false);

      setSeoKeywordsEn(data.seoKeywordsEn);
      setSeoDescEn(data.seoDescEn);
      setSeoTitleEn(data.seoTitleEn);
    }
  };

  const handledmhealthcare = async () => {
    console.log(textEditor);
    const response = await fetch(`${url}/api/v1/user/chairmain/update`, {
      method: "POST",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lan,
        title,
        textEditor,
        seoTitleEn,
        seoKeywordsEn,
        seoDescEn,
        seoTitleAr,
        seoKeywordsAr,
        seoDescAr,
      }),
    });
    const result = await response.json();
    console.log(result);
    toast(result.message);
  };

  return (
    <div class="row g-3">
      <div class="col-md-12">
        <h4>CHAIRMAN’S MESSAGE</h4>
        <label for="select2Basic" class="form-label">
          <b>Language</b>
        </label>
        <select
          id="select2Basic"
          class="select2 form-select"
          data-allow-clear="true"
          onChange={(e) => handleLanguge(e)}
        >
          <option value="">Select</option>
          <option value="En">En</option>
          <option value="Ar">Ar</option>
        </select>
      </div>
      {hidden == false && (
        <>
          <div class="col-sm-12">
            <label class="form-label" for="username">
              <b>Title</b>
            </label>
            <input
              dir={lan === "Ar" ? "rtl" : "ltr"}
              type="text"
              id="username"
              class="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div class="col-sm-12">
            <label class="form-label" for="username">
              <b>Description</b>
            </label>
            <Editor
              apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
              initialValue={textEditor}
              onEditorChange={(content) => setTextEditor(content)}
              init={{
                height: 400,
                menubar: false,
                directionality: lan === "Ar" ? "rtl" : "ltr",
                language: lan === "Ar" ? "ar" : "en",
                language_url: "",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | styles | formatselect | " +
                  "bold italic underline strikethrough | " +
                  "alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | " +
                  "link image media table blockquote | selectall",
                style_formats: [
                  { title: "Heading 1", block: "h1" },
                  { title: "Heading 2", block: "h2" },
                  { title: "Heading 3", block: "h3" },
                  { title: "Heading 4", block: "h4" },
                  { title: "Heading 5", block: "h5" },
                  { title: "Heading 6", block: "h6" },
                  { title: "Paragraph", block: "p" },
                  { title: "Blockquote", block: "blockquote" },
                  { title: "Code", block: "code" },
                ],
                content_style:
                  "body { font-family: Arial, sans-serif; font-size: 14px; }",
                branding: false,
              }}
            />
            {lan === "en" ? (
              <>
                <div className="card mb-3 mt-3">
                  <h5 className="card-header">SEO (En)</h5>

                  <div class="card-body">
                    <div className="mb-3">
                      <label className="form-label">Keywords</label>
                      <input
                        value={seoKeywordsEn}
                        onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        value={seoDescEn}
                        onChange={(ev) => setSeoDescEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        value={seoTitleEn}
                        onChange={(ev) => setSeoTitleEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="card mb-3">
                  <h5 className="card-header">SEO (Ar)</h5>

                  <div class="card-body">
                    <div className="mb-3">
                      <label className="form-label">Keywords</label>
                      <input
                        value={seoKeywordsAr}
                        onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        value={seoDescAr}
                        onChange={(ev) => setSeoDescAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        value={seoTitleAr}
                        onChange={(ev) => setSeoTitleAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <button
              class="btn btn-success btn-submit mt-4"
              onClick={handledmhealthcare}
            >
              Submit
            </button>
            <ToastContainer />
          </div>
        </>
      )}
    </div>
  );
}
