import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import "../../i18n";

import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { url } from "../../Components/Common/Url";

const BmiForm = () => {
  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [textEditor, setTextEditor] = useState("");
  const [title, setTitle] = useState("");
  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${url}/api/v1/healthtool/bmi/update/`, {
      method: "PUT",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lan: activeLanguage,
        textEditor,
        title,
        seoTitleEn,
        seoDescEn,
        seoKeywordsEn,
        seoTitleAr,
        seoDescAr,
        seoKeywordsAr,
      }),
    });
    const result = await response.json();

    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const get_bmi_detail = async () => {
    const response = await fetch(`${url}/api/v1/healthtool/bmi/get/`, {
      method: "GET",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    console.log(result);
    if (activeLanguage == "ar") {
      setTitle(result.data.ar_title);
      setTextEditor(result.data.ar_description);

      setSeoKeywordsAr(result.data.seoKeywordsAr);
      setSeoDescAr(result.data.seoDescAr);
      setSeoTitleAr(result.data.seoTitleAr);
    } else {
      setTitle(result.data.en_title);
      setTextEditor(result.data.en_description);
      setSeoKeywordsEn(result.data.seoKeywordsEn);
      setSeoDescEn(result.data.seoDescEn);
      setSeoTitleEn(result.data.seoTitleEn);
    }
  };

  useEffect(() => {
    get_bmi_detail();
  }, [activeLanguage]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-lg-12">
                      <label className="form-label">{t("Title")}</label>
                      <input
                        type="text"
                        dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">{t("Description")}</label>
                      <Editor
                        key={`editor1-${activeLanguage}`}
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API Key
                        value={textEditor} // Current editor content
                        init={{
                          height: 400,
                          menubar: false,
                          directionality: "ltr",
                          language: "en",
                          language_url: "",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | styles | formatselect | " +
                            "bold italic underline strikethrough | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist outdent indent | " +
                            "link image media table blockquote | selectall",
                          style_formats: [
                            { title: "Heading 1", block: "h1" },
                            { title: "Heading 2", block: "h2" },
                            { title: "Heading 3", block: "h3" },
                            { title: "Heading 4", block: "h4" },
                            { title: "Heading 5", block: "h5" },
                            { title: "Heading 6", block: "h6" },
                            { title: "Paragraph", block: "p" },
                            { title: "Blockquote", block: "blockquote" },
                            { title: "Code", block: "code" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }",
                          branding: false,
                        }}
                        onEditorChange={(content, editor) => {
                          setTextEditor(content); // Update the textEditor state with the new content
                        }}
                      />
                    </div>

                    {activeLanguage === "en" ? (
                      <>
                        <div className="card mb-3 mt-3">
                          <h5 className="card-header">SEO (En)</h5>

                          <div class="card-body">
                            <div className="mb-3">
                              <label className="form-label">Keywords</label>
                              <input
                                value={seoKeywordsEn}
                                onChange={(ev) =>
                                  setSeoKeywordsEn(ev.target.value)
                                }
                                className="form-control"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <input
                                value={seoDescEn}
                                onChange={(ev) => setSeoDescEn(ev.target.value)}
                                className="form-control"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Title</label>
                              <input
                                value={seoTitleEn}
                                onChange={(ev) =>
                                  setSeoTitleEn(ev.target.value)
                                }
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="card mb-3">
                          <h5 className="card-header">SEO (Ar)</h5>

                          <div class="card-body">
                            <div className="mb-3">
                              <label className="form-label">Keywords</label>
                              <input
                                value={seoKeywordsAr}
                                onChange={(ev) =>
                                  setSeoKeywordsAr(ev.target.value)
                                }
                                className="form-control"
                                dir="rtl"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <input
                                value={seoDescAr}
                                onChange={(ev) => setSeoDescAr(ev.target.value)}
                                className="form-control"
                                dir="rtl"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Title</label>
                              <input
                                value={seoTitleAr}
                                onChange={(ev) =>
                                  setSeoTitleAr(ev.target.value)
                                }
                                className="form-control"
                                dir="rtl"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div class="pt-4">
                    <button
                      type="submit"
                      class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BmiForm;
