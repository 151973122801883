import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ToggleButton.css";

const ToggleButton = ({ onToggle, initialState = false, language = "en" }) => {
  const [isToggled, setIsToggled] = useState(initialState);

  useEffect(() => {
    setIsToggled(initialState);
  }, [initialState]);

  const handleToggle = () => {
    const newState = !isToggled;
    setIsToggled(newState);
    onToggle(newState);
  };

  const labels = {
    en: {
      required: "Required",
      notRequired: "Not Required",
    },
    ar: {
      required: "إلزامي",
      notRequired: "غير إلزامي",
    },
  };

  return (
    <div
      className="toggle-button"
      onClick={handleToggle}
      role="switch"
      aria-checked={isToggled}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") handleToggle();
      }}
    >
      <span className="toggle-label">
        {isToggled ? labels[language].required : labels[language].notRequired}
      </span>
      <div className={`toggle-circle ${isToggled ? "toggled" : ""}`}></div>
    </div>
  );
};

ToggleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  initialState: PropTypes.bool,
  language: PropTypes.oneOf(["en", "ar"]),
};

export default ToggleButton;
