import { BASE_URL } from "../Components/Common/Url";
import axios from "axios";

const FormServices = {
  CreateSpecialityService: async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));
    console.log("token ==>", token);
    try {
      console.log("token ==>", token);
      const response = axios.post(
        `${BASE_URL}api/v1/specialities/create`,
        formData,
        {
          headers: {
            token: `${token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  createFacility: async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = axios.post(`${BASE_URL}api/v1/facilities`, formData, {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  updateFacility: async (id, data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    const response = await axios.put(
      `${BASE_URL}api/v1/facilities/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  },

  fetchFacilities: async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/facilities`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  fetchSpecialities: async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/specialities/list`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  fetchLocations: async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/locations`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  fetchCities: async (locationId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/v1/cities/${locationId}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  fetchAreas: async (cityId) => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/areas/${cityId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  deleteFacility: async (facilityId) => {
    await axios.delete(`${BASE_URL}api/v1/facilities/${facilityId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  },

  fetchSpecialties: async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/specialities/list`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  deleteSpecialty: async (id) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}api/v1/specialities/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getSpecialtyById: async (id) => {
    const response = await axios.get(`${BASE_URL}api/v1/specialities/${id}`);
    return response.data;
  },

  updateSpecialty: async (id, data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    const response = await axios.put(
      `${BASE_URL}api/v1/specialities/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  },

  // Fetch FAQs by specialty ID
  fetchFAQsBySpecialty: async (specialtyId) => {
    const response = await axios.get(
      `${BASE_URL}api/v1/specialities/${specialtyId}/faqs`
    );
    return response.data;
  },

  // Create a new FAQ
  createFAQ: async (formData, specialtyId) => {
    const response = await axios.post(
      `${BASE_URL}api/v1/specialities/${specialtyId}/faqs`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  },

  // Update an existing FAQ
  updateFAQ: async (faqId, formData) => {
    const response = await axios.put(
      `${BASE_URL}api/v1/specialities/faqs/${faqId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  },

  // Delete an FAQ
  deleteFAQ: async (faqId) => {
    await axios.delete(`${BASE_URL}api/v1/specialities/faqs/${faqId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    // No need to return response.data for delete operations
  },

  createClinic: async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      console.log("formdata =>", formData.details.gallery);
      const response = axios.post(`${BASE_URL}api/v1/clinic`, formData, {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  createArClinic: async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      console.log("formdata =>", formData.details.gallery);
      const response = axios.post(`${BASE_URL}api/v1/clinic/ar`, formData, {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  updateClinic: async (formData, clinicId) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await axios.put(
      `${BASE_URL}api/v1/clinic/${clinicId}`,
      formData,
      {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    return response.data;
  },

  updateArClinic: async (formData, clinicId) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await axios.put(
      `${BASE_URL}api/v1/clinic/ar/${clinicId}`,
      formData,
      {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    return response.data;
  },

  getClinicById: async (id) => {
    const response = await axios.get(`${BASE_URL}api/v1/clinic/${id}`);
    return response.data;
  },

  fetchClinics: async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/clinic`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  fetchClinicsList: async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/clinic/list`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  deleteClinic: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}api/v1/clinic/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  /*Doctors */

  createDoctor: async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      console.log("formdata =>", formData);
      const response = axios.post(`${BASE_URL}api/v1/doctor`, formData, {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      } else {
        console.log("Oops, there seems to be an error");
      }
    }
  },

  createDoctorAr: async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const response = axios.post(`${BASE_URL}api/v1/doctor/ar`, formData, {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      } else {
        console.log("Oops, there seems to be an error");
      }
    }
  },

  fetchUsers: async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/user/list`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  deleteUser: async (id) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}api/v1/user/delete/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  fetchDoctors: async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/v1/doctor`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getDoctorById: async (id) => {
    const response = await axios.get(`${BASE_URL}api/v1/doctor/${id}`);
    return response.data;
  },

  updateDoctor: async (formData, doctorId) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await axios.put(
      `${BASE_URL}api/v1/doctor/${doctorId}`,
      formData,
      {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    return response.data;
  },

  updateArDoctor: async (formData, doctorId) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await axios.put(
      `${BASE_URL}api/v1/doctor/ar/${doctorId}`,
      formData,
      {
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    return response.data;
  },

  deleteDoctor: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}api/v1/doctor/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};

export default FormServices;
