import React, { useState, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";
import EnAddUser from "../../Forms/EnAddUser";

const AddUser = () => {
  const [key, setKey] = useState("english");

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col">
            <div className="card mb-3">
              <h5 className="card-header">Add User </h5>
              <div className="card-header pt-2">
                <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                  <Nav variant="tabs">
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="english">
                      <EnAddUser />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
