import React, { useState } from "react";
import ExecutiveMemberList from "./ExecutiveMemberList";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { ToastContainer, toast } from "react-toastify";
import { url } from "../Common/Url";
import axios from "axios";

export default function ExecutiveMember() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [entextEditor, setEnTextEditor] = useState("");
  const [artextEditor, setArTextEditor] = useState("");
  const [slug, setSlug] = useState("");
  const [entitle, setEnTitle] = useState("");
  const [artitle, setArTitle] = useState("");

  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const [file, setFile] = useState();

  const { token } = useSelector((state) => state.user);
  const handleExecutive = async () => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("entextEditor", entextEditor);
      formData.append("artextEditor", artextEditor);
      formData.append("entitle", entitle);
      formData.append("slug", slug);
      formData.append("artitle", artitle);

      formData.append("seoTitleEn", seoTitleEn);
      formData.append("seoKeywordsEn", seoKeywordsEn);
      formData.append("seoDescEn", seoDescEn);
      formData.append("seoTitleAr", seoTitleAr);
      formData.append("seoKeywordsAr", seoKeywordsAr);
      formData.append("seoDescAr", seoDescAr);
      const { data } = await axios.post(
        `${url}/api/v1/user/executive/store`,
        formData,
        {
          headers: {
            token: `${token}`,
          },
        }
      );
      if (data.status == true) {
        toast(data.message);
        window.location.reload();
      }
    } catch (err) {
      if (err.response.status === 500) {
        console.log("There was a problem with the server");
      } else {
        console.log(err.response.data.msg);
      }
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Add Member
      </Button>
      <ExecutiveMemberList />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Executive Memeber</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row g-3">
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Image</b>
              </label>
              <input
                type="file"
                id="username"
                class="form-control"
                filename={file}
                onChange={(e) => setFile(e.target.files[0])}
                accept="image/*"
              />
            </div>
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Title (En)</b>
              </label>
              <input
                type="text"
                id="username"
                class="form-control"
                value={entitle}
                onChange={(e) => {
                  setEnTitle(e.target.value);
                  setSlug(e.target.value.split(" ").join("-").toLowerCase());
                }}
              />
            </div>
            <div class="col-sm-12">
              <label class="form-label" for="slug">
                <b>Slug</b>
              </label>
              <input
                type="text"
                id="slug"
                class="form-control"
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
              />
            </div>
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Description (En) </b>
              </label>
              <Editor
                apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API Key
                value={entextEditor}
                onEditorChange={(content) => setEnTextEditor(content)}
                init={{
                  height: 400,
                  menubar: false,
                  directionality: "ltr",
                  language: "en",
                  language_url: "",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | styles | formatselect | " +
                    "bold italic underline strikethrough | " +
                    "alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist outdent indent | " +
                    "link image media table blockquote | selectall",
                  style_formats: [
                    { title: "Heading 1", block: "h1" },
                    { title: "Heading 2", block: "h2" },
                    { title: "Heading 3", block: "h3" },
                    { title: "Heading 4", block: "h4" },
                    { title: "Heading 5", block: "h5" },
                    { title: "Heading 6", block: "h6" },
                    { title: "Paragraph", block: "p" },
                    { title: "Blockquote", block: "blockquote" },
                    { title: "Code", block: "code" },
                  ],
                  content_style:
                    "body { font-family: Arial, sans-serif; font-size: 14px; }",
                  branding: false,
                }}
              />

              <label class="form-label" for="username">
                <b>Title (Ar)</b>
              </label>
              <input
                dir="rtl"
                type="text"
                id="username"
                class="form-control"
                value={artitle}
                onChange={(e) => setArTitle(e.target.value)}
              />

              <label class="form-label" for="username">
                <b>Description (Ar) </b>
              </label>
              <Editor
                apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API Key
                value={artextEditor}
                onEditorChange={(content) => setArTextEditor(content)}
                init={{
                  height: 400,
                  menubar: false,
                  directionality: "ltr",
                  language: "en",
                  language_url: "",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | styles | formatselect | " +
                    "bold italic underline strikethrough | " +
                    "alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist outdent indent | " +
                    "link image media table blockquote | selectall",
                  style_formats: [
                    { title: "Heading 1", block: "h1" },
                    { title: "Heading 2", block: "h2" },
                    { title: "Heading 3", block: "h3" },
                    { title: "Heading 4", block: "h4" },
                    { title: "Heading 5", block: "h5" },
                    { title: "Heading 6", block: "h6" },
                    { title: "Paragraph", block: "p" },
                    { title: "Blockquote", block: "blockquote" },
                    { title: "Code", block: "code" },
                  ],
                  content_style:
                    "body { font-family: Arial, sans-serif; font-size: 14px; }",
                  branding: false,
                }}
              />

              <div className="card mb-3 mt-3">
                <h5 className="card-header">SEO (En)</h5>

                <div class="card-body">
                  <div className="mb-3">
                    <label className="form-label">Keywords</label>
                    <input
                      value={seoKeywordsEn}
                      onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      value={seoDescEn}
                      onChange={(ev) => setSeoDescEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      value={seoTitleEn}
                      onChange={(ev) => setSeoTitleEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <h5 className="card-header">SEO (Ar)</h5>

                <div class="card-body">
                  <div className="mb-3">
                    <label className="form-label">Keywords</label>
                    <input
                      value={seoKeywordsAr}
                      onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      value={seoDescAr}
                      onChange={(ev) => setSeoDescAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      value={seoTitleAr}
                      onChange={(ev) => setSeoTitleAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                </div>
              </div>

              <button
                class="btn btn-success btn-submit mt-4"
                onClick={handleExecutive}
              >
                Submit
              </button>
              <ToastContainer />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
